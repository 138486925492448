import React, { useEffect, useState } from "react";
import InsuredObjectDataService from "../../services/insured.object.data.service";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  pricePattern,
  percentagePattern,
  percentagePatternPremium,
} from "../../patterns/patterns";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import styles from "./HullForm.module.scss";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";

const HullForm = (props) => {
  // Variable with the values for the input fields
  const data = props.editData;
  console.log(props.editData);
  console.log("Hull Form:" + props.isEditing);

  // State for all ships to be displayed in the select field
  const [ship, setShip] = useState([]);

  // Function to get all ships out of the databse.
  const getAllShips = () => {
    InsuredObjectDataService.getAll({ filter: "" })
      .then((response) => {
        console.log(response.data);
        let ships = [];
        response.data.forEach((element) => {
          ships.push({ value: element, label: element.shipname });
        });
        setShip(ships);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get ships if the component gets rendered for the first time
  useEffect(() => {
    getAllShips();
  }, []);

  // Object schema to validate the input fields.
  const HullObjectSchema = Yup.object().shape(
    {
      insurance_sum: Yup.string()
        .required("Pflichtfeld")
        .matches(
          pricePattern,
          "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
        ),
      insurance_start: Yup.date().required("Pflichtfeld"),
      vz: Yup.boolean(),
      payment_method: Yup.string().nullable(),
      hull_liability: Yup.string()
        .matches(
          pricePattern,
          "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
        )
        .nullable(),
      machine_franchise_fixed: Yup.string()
        .matches(
          pricePattern,
          "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
        )
        .nullable(),
      msb: Yup.string()
        .matches(
          pricePattern,
          "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
        )
        .nullable(),
      tpl: Yup.string()
        .matches(
          pricePattern,
          "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
        )
        .nullable(),
      tax: Yup.string()
        .matches(
          percentagePattern,
          "Mit Komma trennen maximal 2 Nachkommastellen"
        )
        .nullable(),
      tax_two: Yup.string()
        .matches(
          percentagePattern,
          "Mit Komma trennen maximal 2 Nachkommastellen"
        )
        .nullable(),
      wreck_sum: Yup.string().when("wreck_rate", {
        is: (wreck_rate) => wreck_rate !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      wreck_rate: Yup.string().when("wreck_sum", {
        is: (wreck_sum) => wreck_sum !== undefined,
        then: Yup.string()
          .required("Pflichtfeld")
          .matches(
            percentagePatternPremium,
            "Mit Komma trennen maximal 4 Nachkommastellen"
          ),
        otherwise: Yup.string()
          .matches(
            percentagePatternPremium,
            "Mit Komma trennen maximal 4 Nachkommastellen"
          )
          .nullable(),
      }),
      collision_liability_sum: Yup.string().when("collision_liability_rate", {
        is: (collision_liability_rate) =>
          collision_liability_rate !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      collision_liability_rate: Yup.string().when("collision_liability_sum", {
        is: (collision_liability_sum) => collision_liability_sum !== undefined,
        then: Yup.string()
          .required("Pflichtfeld")
          .matches(
            percentagePatternPremium,
            "Mit Komma trennen maximal 4 Nachkommastellen"
          ),
        otherwise: Yup.string()
          .matches(
            percentagePatternPremium,
            "Mit Komma trennen maximal 4 Nachkommastellen"
          )
          .nullable(),
      }),
      minen_sum: Yup.string().when("minen_rate", {
        is: (minen_rate) => minen_rate !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      minen_rate: Yup.string().when("minen_sum", {
        is: (minen_sum) => minen_sum !== undefined,
        then: Yup.string()
          .required("Pflichtfeld")
          .matches(
            percentagePatternPremium,
            "Mit Komma trennen maximal 4 Nachkommastellen"
          ),
        otherwise: Yup.string()
          .matches(
            percentagePatternPremium,
            "Mit Komma trennen maximal 4 Nachkommastellen"
          )
          .nullable(),
      }),
      effects_sum: Yup.string().when("effects_rate", {
        is: (effects_rate) => effects_rate !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      effects_rate: Yup.string().when("effects_sum", {
        is: (effects_sum) => effects_sum !== undefined,
        then: Yup.string()
          .required("Pflichtfeld")
          .matches(
            percentagePatternPremium,
            "Mit Komma trennen maximal 4 Nachkommastellen"
          ),
        otherwise: Yup.string()
          .matches(
            percentagePatternPremium,
            "Mit Komma trennen maximal 4 Nachkommastellen"
          )
          .nullable(),
      }),
      extended_hull_sum: Yup.string().when("extended_hull_rate", {
        is: (extended_hull_rate) => extended_hull_rate !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      extended_hull_rate: Yup.string().when("extended_hull_sum", {
        is: (extended_hull_sum) => extended_hull_sum !== undefined,
        then: Yup.string()
          .matches(
            percentagePatternPremium,
            "Mit Komma trennen maximal 4 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            percentagePatternPremium,
            "Mit Komma trennen maximal 4 Nachkommastellen"
          )
          .nullable(),
      }),
      brokerage: Yup.string()
        .matches(
          percentagePattern,
          "Mit Komma trennen maximal 2 Nachkommastellen"
        )
        .required("Pflichtfeld"),
      ship: Yup.object().required("Pflichtfeld"),
      insurance_period_from: Yup.date().required("Pflichtfeld"),
      insurance_period_to: Yup.date().required("Pflichtfeld"),
      premium_rate: Yup.string()
          .required("Prämie oder Prämiensatz muss ausgefüllt sein.")
          .matches(
            percentagePatternPremium,
            "Mit Komma trennen und maximal 4 Nachkommstellen"
          ),
      tax_free: Yup.boolean(),
    },
    [
      ["extended_hull_sum", "extended_hull_rate"],
      ["effects_sum", "effects_rate"],
      ["minen_sum", "minen_rate"],
      ["collision_liability_sum", "collision_liability_rate"],
      ["wreck_sum", "wreck_rate"],
    ]
  );

  return (
    <>
      <Formik
        validationSchema={HullObjectSchema}
        onSubmit={(values) => {
          const ship = values.ship;
          props.onSubmit(values);
          values.ship = ship;
        }}
        initialValues={{
          policy_number: data !== undefined ? data.policy_number : "",
          insurance_sum: data !== undefined ? data.insurance_sum : "",
          insurance_start: data !== undefined ? data.insurance_start : "",
          vz: data !== undefined ? data.vz : false,
          payment_method: data !== undefined ? data.payment_method : "",
          hull_liability:
            data !== undefined && data.hull_liability !== null
              ? data.hull_liability
              : "",
          machine_franchise_fixed:
            data !== undefined && data.machine_franchise_fixed !== null
              ? data.machine_franchise_fixed
              : "",
          msb: data !== undefined && data.msb !== null ? data.msb : "",
          tpl: data !== undefined && data.tpl !== null ? data.tpl : "",
          tax: data !== undefined && data.tax !== null ? data.tax : "",
          tax_two:
            data !== undefined && data.tax_two !== null ? data.tax_two : "",
          wreck_sum:
            data !== undefined && data.wreck_sum !== null ? data.wreck_sum : "",
          wreck_rate:
            data !== undefined && data.wreck_rate !== null
              ? data.wreck_rate
              : "",
          wreck_fifty_one_sum:
            data !== undefined && data.wreck_fifty_one_sum !== null
              ? data.wreck_fifty_one_sum
              : "",
          collision_liability_sum:
            data !== undefined && data.collision_liability_sum !== null
              ? data.collision_liability_sum
              : "",
          collision_liability_rate:
            data !== undefined && data.collision_liability_rate !== null
              ? data.collision_liability_rate
              : "",
          collision_fifty_one_sum:
            data !== undefined && data.collision_fifty_one_sum !== null
              ? data.collision_fifty_one_sum
              : "",
          minen_sum:
            data !== undefined && data.minen_sum !== null ? data.minen_sum : "",
          minen_rate:
            data !== undefined && data.minen_rate !== null
              ? data.minen_rate
              : "",
          minen_fifty_one_sum:
            data !== undefined && data.minen_fifty_one_sum !== null
              ? data.minen_fifty_one_sum
              : "",
          effects_sum:
            data !== undefined && data.effects_sum !== null
              ? data.effects_sum
              : "",
          effects_rate:
            data !== undefined && data.effects_rate !== null
              ? data.effects_rate
              : "",
          effects_fifty_one_sum:
            data !== undefined && data.effects_fifty_one_sum !== null
              ? data.effects_fifty_one_sum
              : "",
          extended_hull_sum:
            data !== undefined && data.extended_hull_sum !== null
              ? data.extended_hull_sum
              : "",
          extended_hull_rate:
            data !== undefined && data.extended_hull_rate !== null
              ? data.extended_hull_rate
              : "",
          extended_fifty_one_sum:
            data !== undefined && data.extended_fifty_one_sum !== null
              ? data.extended_fifty_one_sum
              : "",
          termination:
            data !== undefined && data.termination !== null
              ? data.termination
              : "",
          brokerage: data !== undefined ? data.brokerage : "",
          ship:
            data !== undefined
              ? { value: data.ship, label: data.ship.shipname }
              : "",
          insurance_period_from:
            data !== undefined ? data.insurance_period_from : "",
          insurance_period_to:
            data !== undefined ? data.insurance_period_to : "",
          premium_rate: data !== undefined ? data.premium_rate : "",
          tax_free: data !== undefined ? data.tax_free : false,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
            <Form.Group as={Col} controlId="formGridShip">
                <Form.Label>Schiff</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  id="ship"
                  name="ship"
                  onChange={(selected) => {
                    values.ship = selected;
                    setFieldValue("ship", selected);
                  }}
                  onBlur={handleBlur}
                  onMenuClose={() => setFieldTouched("ship", true, false)}
                  placeholder="Wähle ein Schiff..."
                  options={ship}
                  value={values.ship}
                  styles={chooseCustomSelectStyle(
                    errors.ship,
                    values.ship,
                    touched.ship
                  )}
                />
                {errors.ship && touched.ship ? (
                  <div className={styles["invalid-error"]}>{errors.ship}</div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridInsuranceSum">
                <Form.Label>Versicherungssumme</Form.Label>
                <Form.Control
                  name="insurance_sum"
                  type="text"
                  placeholder="Versicherungssumme"
                  value={values.insurance_sum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("insurance_sum", true, false)}
                  isValid={touched.insurance_sum && !errors.insurance_sum}
                  isInvalid={!!errors.insurance_sum && touched.insurance_sum}
                />
                {errors.insurance_sum && touched.insurance_sum ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_sum}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridPremiumRate">
                <Form.Label>Prämiensatz Kunde</Form.Label>
                <Form.Control
                  name="premium_rate"
                  type="text"
                  placeholder="Prämiensatz"
                  value={values.premium_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("premium_rate", true, false)}
                  isValid={touched.premium_rate && !errors.premium_rate}
                  isInvalid={!!errors.premium_rate && touched.premium_rate}
                />
                {errors.premium_rate && touched.premium_rate ? (
                  <div className={styles["invalid-error"]}>
                    {errors.premium_rate}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridInsuranceStart">
                <Form.Label>Versicherungsbeginn</Form.Label>
                <Form.Control
                  name="insurance_start"
                  type="date"
                  placeholder="Versicherungsbeginn"
                  value={values.insurance_start}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_start", true, false)
                  }
                  isValid={touched.insurance_start && !errors.insurance_start}
                  isInvalid={
                    !!errors.insurance_start && touched.insurance_start
                  }
                  disabled={props.isEditing}
                />
                {errors.insurance_start && touched.insurance_start ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_start}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPaymentMethod">
                <Form.Label>Zahlungsmethode</Form.Label>
                <Form.Select
                  name="payment_method"
                  value={values.payment_method}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.payment_method && !errors.payment_method}
                  isInvalid={!!errors.payment_method && touched.payment_method}
                >
                  <option hidden>Methode wählen...</option>
                  <option></option>
                  <option>1</option>
                  <option>1/2</option>
                  <option>1/3</option>
                  <option>1/4</option>
                  <option>1/6</option>
                  <option>1/12</option>
                </Form.Select>
                {errors.payment_method && touched.payment_method ? (
                  <div className={styles["invalid-error"]}>
                    {errors.payment_method}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridInsurancePeriodFrom">
                <Form.Label>Versicherungszeitraum von</Form.Label>
                <Form.Control
                  name="insurance_period_from"
                  type="date"
                  placeholder="Versicherungszeitraum von"
                  value={values.insurance_period_from}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_period_from", true, false)
                  }
                  isValid={
                    touched.insurance_period_from &&
                    !errors.insurance_period_from
                  }
                  isInvalid={
                    !!errors.insurance_period_from &&
                    touched.insurance_period_from
                  }
                />
                {errors.insurance_period_from &&
                touched.insurance_period_from ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_period_from}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridInsurancePeriodTo">
                <Form.Label>Versicherungszeitraum bis</Form.Label>
                <Form.Control
                  name="insurance_period_to"
                  type="date"
                  placeholder="Versicherungszeitraum bis"
                  value={values.insurance_period_to}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_period_to", true, false)
                  }
                  isValid={
                    touched.insurance_period_to && !errors.insurance_period_to
                  }
                  isInvalid={
                    !!errors.insurance_period_to && touched.insurance_period_to
                  }
                />
                {errors.insurance_period_to && touched.insurance_period_to ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_period_to}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridHullLiability">
                <Form.Label>Kasko-Haftpflicht</Form.Label>
                <Form.Control
                  name="hull_liability"
                  type="text"
                  placeholder="Kasko-Haftpflicht"
                  value={values.hull_liability}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("hull_liability", true, false)}
                  isValid={touched.hull_liability && !errors.hull_liability}
                  isInvalid={!!errors.hull_liability && touched.hull_liability}
                />
                {errors.hull_liability && touched.hull_liability ? (
                  <div className={styles["invalid-error"]}>
                    {errors.hull_liability}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group
                as={Col}
                controlId="formGridLoadMachineFranchiseFixed"
              >
                <Form.Label>Maschinenfranchise-Fest</Form.Label>
                <Form.Control
                  name="machine_franchise_fixed"
                  type="text"
                  placeholder="Machinenfranchise-Fest"
                  value={values.machine_franchise_fixed}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("machine_franchise_fixed", true, false)
                  }
                  isValid={
                    touched.machine_franchise_fixed &&
                    !errors.machine_franchise_fixed
                  }
                  isInvalid={
                    !!errors.machine_franchise_fixed &&
                    touched.machine_franchise_fixed
                  }
                />
                {errors.machine_franchise_fixed &&
                touched.machine_franchise_fixed ? (
                  <div className={styles["invalid-error"]}>
                    {errors.machine_franchise_fixed}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridMsb">
                <Form.Label>MSB</Form.Label>
                <Form.Control
                  name="msb"
                  type="text"
                  placeholder="MSB"
                  value={values.msb}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("msb", true, false)}
                  isValid={touched.msb && !errors.msb}
                  isInvalid={!!errors.msb && touched.msb}
                />
                {errors.msb && touched.msb ? (
                  <div className={styles["invalid-error"]}>{errors.msb}</div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridTpl">
                <Form.Label>TPL</Form.Label>
                <Form.Control
                  name="tpl"
                  type="text"
                  placeholder="TPL"
                  value={values.tpl}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("tpl", true, false)}
                  isValid={touched.tpl && !errors.tpl}
                  isInvalid={!!errors.tpl && touched.tpl}
                />
                {errors.tpl ? (
                  <div className={styles["invalid-error"]}>{errors.tpl}</div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridTaxOne">
                <Form.Label>Kasko Steuer</Form.Label>
                <Form.Control
                  name="tax"
                  type="text"
                  placeholder="Kasko Steuer"
                  value={values.tax}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("tax", true, false)}
                  isValid={touched.tax && !errors.tax}
                  isInvalid={!!errors.tax && touched.tax}
                />
                {errors.tax && touched.tax ? (
                  <div className={styles["invalid-error"]}>{errors.tax}</div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridTaxTwo">
                <Form.Label>Andere Steuer</Form.Label>
                <Form.Control
                  name="tax_two"
                  type="text"
                  placeholder="Andere Steuer"
                  value={values.tax_two}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("tax_two", true, false)}
                  isValid={touched.tax_two && !errors.tax_two}
                  isInvalid={!!errors.tax_two && touched.tax_two}
                />
                {errors.tax_two && touched.tax_two ? (
                  <div className={styles["invalid-error"]}>
                    {errors.tax_two}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridBrokerage">
                <Form.Label>Courtage</Form.Label>
                <Form.Control
                  name="brokerage"
                  type="text"
                  placeholder="Courtage"
                  value={values.brokerage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("brokerage", true, false)}
                  isValid={touched.brokerage && !errors.brokerage}
                  isInvalid={!!errors.brokerage && touched.brokerage}
                />
                {errors.brokerage && touched.brokerage ? (
                  <div className={styles["invalid-error"]}>
                    {errors.brokerage}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row>
              {props.isEditing && (
                <Form.Group as={Col} controlId="formGridInsuranceStart">
                  <Form.Label>Kündigung zum</Form.Label>
                  <Form.Control
                    name="termination"
                    type="date"
                    placeholder="Kündigung zum"
                    value={values.termination}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onInput={() => setFieldTouched("termination", true, false)}
                    isValid={touched.termination && !errors.termination}
                    isInvalid={!!errors.termination && touched.termination}
                  />
                  {errors.termination && touched.termination ? (
                    <div className={styles["invalid-error"]}>
                      {errors.termination}
                    </div>
                  ) : null}
                </Form.Group>
              )}
              <Form.Group as={Col}>
                <Form.Check
                  name="vz"
                  type="checkbox"
                  label="VZ 7.5%"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.vz && !errors.vz}
                  isInvalid={!!errors.vz && touched.vz}
                  checked={values.vz}
                />
                {errors.vz && touched.vz ? (
                  <div className={styles["invalid-error"]}>{errors.vz}</div>
                ) : null}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Check
                  name="tax_free"
                  type="checkbox"
                  label="Versicherungssteuer frei"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.tax_free && !errors.tax_free}
                  isInvalid={!!errors.tax_free && touched.tax_free}
                  checked={values.tax_free}
                />
                {errors.tax_free && touched.tax_free ? (
                  <div className={styles["invalid-error"]}>
                    {errors.tax_free}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <hr />
            <div className={styles.subheading}>
              <h5>Wrack</h5>
            </div>
            <Row>
              <Form.Group as={Col} controlId="formGridWreckSum">
                <Form.Label>Versicherungssumme</Form.Label>
                <Form.Control
                  name="wreck_sum"
                  type="text"
                  placeholder="Versicherungssumme"
                  value={values.wreck_sum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("wreck_sum", true, false)}
                  isValid={touched.wreck_sum && !errors.wreck_sum}
                  isInvalid={!!errors.wreck_sum && touched.wreck_sum}
                />
                {errors.wreck_sum && touched.wreck_sum ? (
                  <div className={styles["invalid-error"]}>
                    {errors.wreck_sum}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridWreckRate">
                <Form.Label>Prämiensatz</Form.Label>
                <Form.Control
                  name="wreck_rate"
                  type="text"
                  placeholder="Prämiensatz"
                  value={values.wreck_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("wreck_rate", true, false)}
                  isValid={touched.wreck_rate && !errors.wreck_rate}
                  isInvalid={!!errors.wreck_rate && touched.wreck_rate}
                />
                {errors.wreck_rate && touched.wreck_rate ? (
                  <div className={styles["invalid-error"]}>
                    {errors.wreck_rate}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <div className={styles.subheading}>
              <h5>Kollisionshaftpflicht</h5>
            </div>
            <Row>
              <Form.Group as={Col} controlId="formGridCollisionLiabilitySum">
                <Form.Label>Versicherungssumme</Form.Label>
                <Form.Control
                  name="collision_liability_sum"
                  type="text"
                  placeholder="Versicherungssumme"
                  value={values.collision_liability_sum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("collision_liability_sum", true, false)
                  }
                  isValid={
                    touched.collision_liability_sum &&
                    !errors.collision_liability_sum
                  }
                  isInvalid={
                    !!errors.collision_liability_sum &&
                    touched.collision_liability_sum
                  }
                />
                {errors.collision_liability_sum &&
                touched.collision_liability_sum ? (
                  <div className={styles["invalid-error"]}>
                    {errors.collision_liability_sum}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridCollisionLiabilityRate">
                <Form.Label>Prämiensatz</Form.Label>
                <Form.Control
                  name="collision_liability_rate"
                  type="text"
                  placeholder="Prämiensatz"
                  value={values.collision_liability_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("collision_liability_rate", true, false)
                  }
                  isValid={
                    touched.collision_liability_rate &&
                    !errors.collision_liability_rate
                  }
                  isInvalid={
                    !!errors.collision_liability_rate &&
                    touched.collision_liability_rate
                  }
                />
                {errors.collision_liability_rate &&
                touched.collision_liability_rate ? (
                  <div className={styles["invalid-error"]}>
                    {errors.collision_liability_rate}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <div className={styles.subheading}>
              <h5>Minen</h5>
            </div>
            <Row>
              <Form.Group as={Col} controlId="formGridMinenSum">
                <Form.Label>Versicherungssumme</Form.Label>
                <Form.Control
                  name="minen_sum"
                  type="text"
                  placeholder="Versicherungssumme"
                  value={values.minen_sum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("minen_sum", true, false)}
                  isValid={touched.minen_sum && !errors.minen_sum}
                  isInvalid={!!errors.minen_sum && touched.minen_sum}
                />
                {errors.minen_sum && touched.minen_sum ? (
                  <div className={styles["invalid-error"]}>
                    {errors.minen_sum}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridMinenRate">
                <Form.Label>Prämiensatz</Form.Label>
                <Form.Control
                  name="minen_rate"
                  type="text"
                  placeholder="Prämiensatz"
                  value={values.minen_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("minen_rate", true, false)}
                  isValid={touched.minen_rate && !errors.minen_rate}
                  isInvalid={!!errors.minen_rate && touched.minen_rate}
                />
                {errors.minen_rate && touched.minen_rate ? (
                  <div className={styles["invalid-error"]}>
                    {errors.minen_rate}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <div className={styles.subheading}>
              <h5>Effekten</h5>
            </div>
            <Row>
              <Form.Group as={Col} controlId="formGridEffectsSum">
                <Form.Label>Versicherungssumme</Form.Label>
                <Form.Control
                  name="effects_sum"
                  type="text"
                  placeholder="Versicherungssumme"
                  value={values.effects_sum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("effects_sum", true, false)}
                  isValid={touched.effects_sum && !errors.effects_sum}
                  isInvalid={!!errors.effects_sum && touched.effects_sum}
                />
                {errors.effects_sum && touched.effects_sum ? (
                  <div className={styles["invalid-error"]}>
                    {errors.effects_sum}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridWreckRate">
                <Form.Label>Prämiensatz</Form.Label>
                <Form.Control
                  name="effects_rate"
                  type="text"
                  placeholder="Prämiensatz"
                  value={values.effects_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("effects_rate", true, false)}
                  isValid={touched.effects_rate && !errors.effects_rate}
                  isInvalid={!!errors.effects_rate && touched.effects_rate}
                />
                {errors.effects_rate && touched.effects_rate ? (
                  <div className={styles["invalid-error"]}>
                    {errors.effects_rate}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <div className={styles.subheading}>
              <h5>Erweiterte-Kasko</h5>
            </div>
            <Row>
              <Form.Group as={Col} controlId="formGridExtendedHullSum">
                <Form.Label>Versicherungssumme</Form.Label>
                <Form.Control
                  name="extended_hull_sum"
                  type="text"
                  placeholder="Versicherungssumme"
                  value={values.extended_hull_sum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("extended_hull_sum", true, false)
                  }
                  isValid={
                    touched.extended_hull_sum && !errors.extended_hull_sum
                  }
                  isInvalid={!!errors.extended_hull_sum}
                />
                {errors.extended_hull_sum && touched.extended_hull_sum ? (
                  <div className={styles["invalid-error"]}>
                    {errors.extended_hull_sum}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridWreckRate">
                <Form.Label>Prämiensatz</Form.Label>
                <Form.Control
                  name="extended_hull_rate"
                  type="text"
                  placeholder="Prämiensatz"
                  value={values.extended_hull_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("extended_hull_rate", true, false)
                  }
                  isValid={
                    touched.extended_hull_rate && !errors.extended_hull_rate
                  }
                  isInvalid={
                    !!errors.extended_hull_rate && touched.extended_hull_rate
                  }
                />
                {errors.extended_hull_rate && touched.extended_hull_rate ? (
                  <div className={styles["invalid-error"]}>
                    {errors.extended_hull_rate}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <div className={styles.submitBtn}>
              <SubmitButton variant="primary" type="submit">
                {props.btnText}
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default HullForm;
