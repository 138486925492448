import { Formik } from "formik";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../../UI/Button/SubmitButton";
import styles from "./MultipleCorpChoice.module.scss";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../../custom-style/custom-select";

export const multipleChoiceCorpForm = (objectSchema, corpFields, resetCorpFields, corporation, btnText, onSubmit) => {
    return (
        <>
      <Formik
        validationSchema={objectSchema}
        onSubmit={(values) => {
          onSubmit(values);
          resetCorpFields();
        }}
        enableReinitialize
        initialValues={corpFields}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCorporation">
                <Form.Label>Gesellschaft</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  id="corporation"
                  name="corporation"
                  placeholder="Wähle Gesellschaft..."
                  onChange={(selected) => {
                    values.corporation = selected;
                    setFieldValue("corporation", selected);
                  }}
                  onBlur={handleBlur}
                  options={corporation}
                  value={values.corporation}
                  styles={chooseCustomSelectStyle(
                    errors.corporation,
                    values.corporation
                  )}
                />
                {errors.corporation ? (
                  <div className={styles["invalid-error"]}>
                    {errors.corporation}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridShare">
                <Form.Label>Anteil</Form.Label>
                <Form.Control
                  name="share"
                  type="text"
                  placeholder="Anteil"
                  value={values.share}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.share && !errors.share}
                  isInvalid={!!errors.share}
                />
                {errors.share ? (
                  <div className={styles["invalid-error"]}>{errors.share}</div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPremiumRate">
                <Form.Label>Prämiensatz</Form.Label>
                <Form.Control
                  name="premium_rate"
                  type="text"
                  placeholder="Prämiensatz"
                  value={values.premium_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.premium_rate && !errors.premium_rate}
                  isInvalid={!!errors.premium_rate}
                />
                {errors.premium_rate ? (
                  <div className={styles["invalid-error"]}>
                    {errors.premium_rate}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <SubmitButton className="submitBtn" variant="primary" type="submit">
              {btnText}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </>
    )

}