// Checks if a user and a bearer token exist and reads it
// from local storage
export default function authHeader() {
    const token = JSON.parse(localStorage.getItem("keycloakToken"));
    if (token && token.token) {
      console.log("Test")
      return { Authorization: "Bearer " + token.token };
    } else {
      return {};
    }
  }
  