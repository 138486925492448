import React, { useState, useEffect, useCallback } from "react";
import LohDataService from "../../services/loh.data.service";
import Header from "../Header/Header";
import LohForm from "./LohForm";
import { dateFormatterDE } from "../../formatter/date-formatter";
import InvoiceForm from "../Invoice/InvoiceForm";
import InvoiceDataService from "../../services/invoice.data.service";
import useAlert from "../../hooks/use-alert";
import GenericAlert from "../Alert/GenericAlert";
import { checkIfItemIsStringAndReplaceComma } from "../../helper/helper";
import LohChangeForm from "./LohChangeForm";
import lohCorporationDataService from "../../services/loh-corporation.data.service";
import CrudContentLoh from "../CrudContent/CrudContentLoh";

// Headers for the more information view.
export const lohHeaders = [
  "Policennummer:",
  "Versicherungssumme:",
  "Tagessatz:",
  "Prämiensatz Kunde:",
  "Zahlweise:",
  "Selbstbeteiligung:",
  "Selbstbeteiligung Machine:",
  "Max.:",
  "Max. pro Jahr:",
  "Steuer:",
  "Beginn:",
  "Courtage:",
  "Schiff:",
  "Kündigung zum:",
  "51:",
  "Versicherungszeitraum von:",
  "Versicherungszeitraum bis:",
];

// Array to set currency or percentage sign after specific values.
export const lohSignArray = [
  "",
  "€",
  "€",
  "%",
  "",
  "",
  "",
  "",
  "",
  "%",
  "",
  "%",
  "",
  "",
  "€",
  "",
  ""
];

const Loh = () => {
  // The state which holds all lohs.
  const [allLohs, setAllLohs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const { show, alertContent, handleAlertVisible } = useAlert();
  const [loh, setLoh] = useState({});
  const [dueDateIsDisabled, setDueDateIsDisabled] = useState(false);

  const modalHeader = ["Gesellschaft", "Anteil", "Prämiensatz"];

  // The headers to be displayed in the table.
  const header = [
    "Policennummer",
    "Versicherungsumme",
    "Tagessatz",
    "Prämiensatz Kunde",
    "ZW",
    "SB",
    "SB Machine",
    "Max.",
    "Max. pro Jahr",
    "Steuer",
    "Beginn",
    "Courtage",
    "Schiff",
    "Kündigung",
    "Bearbeiten",
  ];

  // The variables for the modals (add, delete, edit)
  const modalTitle = "Loh löschen";
  const modalTitleEdit = "Loh bearbeiten";
  const modalTitleAdd = "Loh hinzufügen";
  const modalBody = "Möchten Sie die Loh wirklich löschen?";

  const initialLohObject = {
    policy_number: "",
    insurance_sum: "",
    daily_rate: "",
    premium_rate: "",
    payment_method: "",
    deductible: "",
    deductible_machine: "",
    hull: "",
    machine: "",
    tax: "",
    insurance_start: "",
    brokerage: "",
    ship: "",
    termination: "",
    fifty_one_sum: "",
    insurance_period_from: "",
    insurance_period_to: "",
  };

  // The data to be edited
  const [editData, setEditData] = useState(initialLohObject);

  // The data to be displayed
  const [displayData, setDisplayData] = useState({
    array: [],
    tableData: [],
  });

  // The filter value out of the filter input on top of the table
  const [filter, setFilter] = useState({ filter: "", year: "" });

  // Count of header items that need to be skipped
  const skip = 1;

  // Function to get all lohs out of the database
  // based on the filtered value.
  const getAllLohs = useCallback(() => {
    LohDataService.getAll(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          // Array which holds all lohs.
          var loh = [];
          response.data.forEach((element) => {
            loh.push({
              policy_number: element.policy_number,
              insurance_sum: element.insurance_sum.toString().replace(".", ","),
              daily_rate: element.daily_rate.toString().replace(".", ","),
              premium_rate: element.premium_rate.toString().replace(".", ","),
              payment_method: element.payment_method,
              deductible: element.deductible,
              deductible_machine: element.deductible_machine,
              hull: element.hull,
              machine: element.machine,
              tax: element.tax !== null ? element.tax.toString().replace(".", ",") : null,
              insurance_start: element.insurance_start,
              brokerage: element.brokerage.toString().replace(".", ","),
              ship: element.ship,
              termination: element.termination,
              fifty_one_sum:
                element.fifty_one_sum !== null
                  ? element.fifty_one_sum.toString().replace(".", ",")
                  : null,
              insurance_period_from: element.insurance_period_from,
              insurance_period_to: element.insurance_period_to,
            });
          });
          // Set all lohs to the loh array created above.
          console.log(loh);
          setAllLohs(loh);
        } else {
          // Set all lohs to its default value.
          setAllLohs([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  // Always calls getAllLohs() if the filter value changes.
  useEffect(() => {
    getAllLohs();
  }, [getAllLohs]);

  const deleteItem = (item) => {
    LohDataService.delete(item.policy_number)
      .then((response) => {
        console.log(response);
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Die LOH-Versicherung "' +
            item.policy_number +
            '" wurde erfolgreich gelöscht!',
          "success"
        );
        getAllLohs();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die LOH-Versicherung "' +
            item.policy_number +
            '" konnte nicht gelöscht werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Replace all commas with dots.
  // Must be done for all decimal values because
  // the backend only accepts data with a dot.
  const replaceComma = (item) => {
    item.insurance_sum = checkIfItemIsStringAndReplaceComma(item.insurance_sum)
    item.daily_rate = checkIfItemIsStringAndReplaceComma(item.daily_rate);
    item.tax = checkIfItemIsStringAndReplaceComma(item.tax);
    item.brokerage = checkIfItemIsStringAndReplaceComma(item.brokerage);
    item.premium_rate = checkIfItemIsStringAndReplaceComma(item.premium_rate)
  };

  const selectValuesFromObjects = (item) => {
    if (item.policy_number.value !== undefined) {
      item.policy_number = item.policy_number.value;
    }
    if (item.ship.value !== undefined) {
      item.ship = item.ship.value;
    }
  };

  // Function to edit a specific loh item.
  const editItem = (item) => {
    selectValuesFromObjects(item);
    replaceComma(item);
    LohDataService.update(item.policy_number, item)
      .then((response) => {
        let loh = response.data;
        console.log(response);
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Die LOH-Versicherung "' +
            item.policy_number +
            '" wurde erfolgreich bearbeitet!',
          "success"
        );
        setLoh(loh);
        setEditData(item);
        getAllLohs(); 
      })
      .catch((error) => {
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Die LOH-Versicherung "' +
            item.policy_number +
            '" konnte nicht bearbeitet werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to create a new loh item in the database
  const addItem = (item) => {
    selectValuesFromObjects(item);
    replaceComma(item);
    LohDataService.create(item)
      .then((response) => {
        let loh = response.data;
        console.log(loh)
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Die LOH-Versicherung "' +
            loh.policy_number +
            '" wurde erfolgreich erstellt!',
          "success"
        );
        getAllLohs();
        setLoh(loh)
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die LOH-Versicherung konnte nicht erstellt werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to set the current filter value.
  const handleFilter = (filterValue) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, filter: filterValue.filter };
    });
  };

  // Function to set the current edit data.
  const handleEditItem = (item) => {
    const data = { ...item };
    console.log(data);
    setEditData(data);
  };

  const getAllCorporations = (item) => {
    lohCorporationDataService.getAll({ filter: item.policy_number })
      .then((response) => {
        if (response.data.lenght !== 0) {
          var lohCorp = [];
          response.data.forEach((element) => {
            console.log(element.loh);
            lohCorp.push({
              corporation: element.corporation,
              share: element.share,
              premium_rate: element.premium_rate,
              loh: element.loh,
            });
          });
          createDisplayDataItem(lohCorp, item)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  // Function to create the display data item.
    const createDisplayDataItem = (lohCorp, item) => {
      console.log(item.fifty_one_sum_result);
      // Create an array which holds multiple arrays each with
      // one key value pair separated by a comma.
      let lohArray = Object.entries(item);
      for (let i = 0; i < lohHeaders.length; i++) {
        // Replace all keys with the header value.
        lohArray[i][0] = lohHeaders[i];
        lohArray[i][2] = lohSignArray[i];
      }
      console.log(lohArray);
      // Set the displayed data.
      setDisplayData({
        array: lohArray,
        tableData: lohCorp,
      });
    };

  // Function which creates an array with the data to be displayed
  // in the more information view.
  const handleDisplayItem = (data) => {
    const item = { ...data };
    // Get one specific attribute of the objects to be displayed.
    item.ship = item.ship.shipname;
    item.insurance_start = dateFormatterDE(item.insurance_start);
    item.termination = dateFormatterDE(item.termination);
    item.insurance_period_from = dateFormatterDE(item.insurance_period_from);
    item.insurance_period_to = dateFormatterDE(item.insurance_period_to);
    console.log(item.policy_number)

    getAllCorporations(item)
  };

  const handleYearChange = (year) => {
    console.log(year);
    setFilter((prevFilter) => {
      return { ...prevFilter, year: year };
    });
  };

  const handleInvoiceCreation = (item) => {
    LohDataService.get(item.div.policy_number)
      .then((response) => {
        const invoiceData = {
          loh: response.data,
          dueDatesList: item.dueDatesList,
        };
        console.log(invoiceData);
        InvoiceDataService.generateLohInvoice(invoiceData)
          .then((response) => {
            handleAlertVisible(
              "Erfolgreiche Rechnungsgenerierung",
              "Die LOH-Rechnung wurde erfolgreich generiert!",
              "success"
            );
            console.log(response);
          })
          .catch((error) => {
            handleAlertVisible(
              "Fehler",
              "Die LOH-Rechnung konnte nicht generiert werden!",
              "danger"
            );
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const setDisabled = (isDisabled) => {
    setDueDateIsDisabled(isDisabled);
  }

  return (
    <main>
      <Header />
      <div className="main">
        <CrudContentLoh
          heading="Loss of Hire"
          items={allLohs}
          header={header}
          onDelete={deleteItem}
          delete
          modalTitle={modalTitle}
          modalBody={modalBody}
          modalTitleEdit={modalTitleEdit}
          form={<LohForm editData={editData} btnText="Bearbeiten" isEditing />}
          onEditItemReceive={handleEditItem}
          onEditItem={editItem}
          placeholder={"Filter Loh..."}
          modalTitleAdd={modalTitleAdd}
          formAdd={<LohForm btnText="Hinzufügen" />}
          onAddItem={addItem}
          add
          onFilter={handleFilter}
          skip={skip}
          attr={{ 12: "shipname" }}
          onDisplayItemReceive={handleDisplayItem}
          displayItems={displayData}
          moreInfo
          signArray={lohSignArray}
          edit
          modalHeader={modalHeader}
          modalHeaders={modalHeader}
          onYearChange={handleYearChange}
          hasYearFilter
          onInvoiceCreation={handleInvoiceCreation}
          invoice
          dueDatesForm={
            <InvoiceForm btnText="Manuelle Fälligkeiten anwenden" isDisabled={dueDateIsDisabled}/>
          }
          dueDatesIsDisabled={setDisabled}
          invoiceModalHeader="LOH Rechnungsgenerierung"
          loading={isLoading}
          unauthorized={isUnauthorized}
          error={error}
          onChangeItem={addItem}
          formChange={<LohChangeForm btnText="Hinzufügen"/>}
          loh={loh}
          changeTitle="Loh Änderung hinzufügen"
        />
      </div>
      <GenericAlert
        show={show}
        alertVariant={alertContent.variant}
        alertHeading={alertContent.heading}
        alertBody={alertContent.message}
      />
    </main>
  );
};

export default Loh;
