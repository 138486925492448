import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import lohCorporationDataService from "../../../../services/loh-corporation.data.service";
import Table from "../../../Table/Table";
import styles from "./CustomLohCorporationModal.module.scss";
import LohCorporationForm from "../../../Loh/LohCorporationForm";
import CustomDeleteModal from "../CustomDeleteModal";
import useAlert from "../../../../hooks/use-alert";
import GenericAlert from "../../../Alert/GenericAlert";
import { checkIfItemIsStringAndReplaceComma } from "../../../../helper/helper";
import { checkIfItemIsStringAndNotNullAndReplaceComma } from "../../../../helper/helper";

const CustomLohCorporationModal = (props) => {
  const { show, alertContent, handleAlertVisible } = useAlert();

  // Variable to display the delete modal
  const [showDel, setShowDel] = useState(false);
  // Variable with the current item which is selected
  const [item, setItem] = useState("");

  // The total share of all corporations together
  // Need to be exact 100% in the end
  const [totalShare, setTotalShare] = useState(0);
  // The percentage that is missing to hit the 100%
  const [missingShare, setMissingShare] = useState(100);

  // Hold the corporations for a specific loh.
  const [corporations, setCorporations] = useState([]);

  // State which indicates if the user is editing or not
  const [isEditing, setIsEditing] = useState(false);

  // Header to be displayed in the table component
  const header = ["Gesellschaft", "Anteil", "Prämiensatz", "Bearbeiten"];
  const lohCorpSignArray = ["", "%", "%"];

  // Headers for the table without the edit field.
  const headers = ["Gesellschaft", "Anteil", "Prämiensatz"];

  // Header and Bodys for the modals
  const modalTitleAddCorp = "Gesellschaften"; // Variable to display the edit modal
  const modalTitleDel = "Gesellschaft löschen";
  const modalBodyDel = "Möchten Sie die Gesellschaft wirklich löschen?";

  // The loh corporation object which is passed to the LohCorporationForm component.
  const [lohCorp, setLohCorp] = useState({
    loh: props.loh,
    corporation: { name: "" },
    share: "",
    premium_rate: "",
  });

  // Function to get all corporations for a specific policynumber out of the database.
  const getAllCorporations = (filter) => {
    lohCorporationDataService
      .getAll(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          // Holds the corporation objects.
          var corp = [];
          // Holds the total share in the end.
          var share = 0;
          // Holds the missing share.
          var missShare = 100;

          // Loops over every corporation item and saves it into the corp variable.
          response.data.forEach((element) => {
            corp.push({
              corporation: element.corporation,
              share: element.share.toString().replace(".", ","),
              premium_rate: element.premium_rate.toString().replace(".", ","),
            });
            // Calculates the total share of all corporations objects.
            share += +element.share.toFixed(4);
          });
          // Calculates the share that is missing to hit 100%
          missShare -= share.toFixed(4);
          // Sets the total share to the calculated share value.
          setTotalShare(share);
          // Sets the missing value to the calculated missing value.
          setMissingShare(missShare.toFixed(4));
          // Set teh corporation state array.
          setCorporations(corp);
        } else {
          // Fill all the setters with their default values.
          setCorporations([]);
          setTotalShare(0);
          setMissingShare(100);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const { policy_number } = props.loh;
  // Only run the getAllCorporations() function again if the policynumber changes.
  useEffect(() => {
    getAllCorporations({ filter: policy_number });
  }, [policy_number]);

  // Function to adjust the decimal values and replace the comma with a dot.
  const formatDecimals = (item) => {
    item.premium_rate = checkIfItemIsStringAndReplaceComma(item.premium_rate);
    item.share = checkIfItemIsStringAndReplaceComma(item.share);
  };

  const selectValuesFromObjects = (item) => {
    item.corporation = item.corporation.value;
  };

  const addLohCorporation = (item) => {
    const data = { ...item };
    selectValuesFromObjects(data);
    // Format decimals
    formatDecimals(data);
    replaceComma(data.loh);
    console.log(data);

    lohCorporationDataService
      .create(data)
      .then((response) => {
        console.log(data);
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Die Gesellschaft mit der Id "' +
            data.corporation.id +
            '" wurde erfolgreich hinzugefügt!',
          "success"
        );
        // Call getAllCorporations to refresh the table
        getAllCorporations({ filter: policy_number });
      })
      .catch((error) => {
        console.log(data);
        handleAlertVisible(
          "Fehler",
          'Die Gesellschaft mit der Id "' +
            data.corporation.id +
            '" konnte nicht hinzugefügt werden!',
          "danger"
        );
        console.log(error);
      });
  };


  // Function to update an loh corporation entry.
    const updateLohCorporation = (item) => {
      const data = {...item};
      selectValuesFromObjects(data);
      console.log(data);
      // Format decimals
      formatDecimals(data);
      replaceComma(data.loh);
      // Calls the update function of the backend to edit an entry
      lohCorporationDataService.update(
        props.loh.policy_number,
        data.corporation.id,
        data
      )
        .then((response) => {
          console.log(data)
          handleAlertVisible(
            "Erfolgreich bearbeitet",
            'Die Gesellschaft mit der Id "' +
            data.corporation.id +
              '" wurde erfolgreich bearbeitet!',
            "success"
          );
          // Call getAllCorporations to refresh the table
          getAllCorporations({ filter: policy_number });
        })
        .catch((error) => {
          handleAlertVisible(
            "Fehler",
            'Die Gesellschaft mit der Id "' +
            data.corporation.id +
              '" konnte nicht bearbeitet werden!',
            "danger"
          );
          console.log(error);
        });
    };
  
    const replaceComma = (item) => {
        item.insurance_sum = checkIfItemIsStringAndReplaceComma(item.insurance_sum)
        item.daily_rate =  checkIfItemIsStringAndNotNullAndReplaceComma(item.daily_rate);
        item.tax =  checkIfItemIsStringAndNotNullAndReplaceComma(item.tax);
        item.brokerage = checkIfItemIsStringAndReplaceComma(item.brokerage);
        item.premium_rate = checkIfItemIsStringAndReplaceComma(item.premium_rate)
      };

      const resetLohCorpFields = () => {
        setLohCorp((prevLohCorp) => {
          replaceComma(prevLohCorp.loh);
          return {
            ...prevLohCorp,
            corporation: { name: "" },
            share: "",
            premium_rate: "",
          };
        });
      };

    // Function to handle the onSubmit for add and edit a loh corporation.
  const handleAddAndEdit = (e) => {
    // Checks wether an item is added or is being edited.
    if (isEditing) {
      updateLohCorporation(e);
      // Sets the LohCorp object to default to clear the input fields.
      resetLohCorpFields();
      setIsEditing(false);
    } else {
      addLohCorporation(e);
      // Sets the LohCorp object to default to clear the input fields.
      //console.log(e);
      //resetLohCorpFields();
    }
  };

  // Function to fill the input fields with the data that was already inserted.
  const handleEdit = (data) => {
    setLohCorp((prevLohCorp) => {
      return {
        ...prevLohCorp,
        corporation: data.corporation,
        premium_rate: data.premium_rate,
        share: data.share,
      };
    });
    // Sets is editing to true that handleAddAndEdit() choose the right onSubmit.
    setIsEditing(true);
  };

  // Function to delete an specific loh corporation in the backend
    const deleteLohCorporation = () => {
      lohCorporationDataService.delete(item.loh_pnr, item.corporation_id)
        .then((response) => {
          handleAlertVisible(
            "Erfolgreich gelöscht",
            'Die Gesellschaft mit der Id "' +
              item.corporation_id +
              '" wurde erfolgreich gelöscht!',
            "success"
          );
          // Call getAllCorporations to refresh the table
          getAllCorporations({ filter: policy_number });
        })
        .catch((error) => {
          handleAlertVisible(
            "Fehler",
            'Die Gesellschaft mit der Id "' +
              item.corporation_id +
              '" konnte nicht gelöscht werden!',
            "danger"
          );
          console.log(error);
        });
    };

    // Function to handle the delete of an specific entry
  const handleDelete = () => {
    // Call the methods which holds the backend deletion call.
    deleteLohCorporation();
    // Close the delete modal.
    setShowDel(false);
  };

  // The functions to open and close the delete modal
  const handleCloseDel = () => setShowDel(false);
  const handleShowDel = (datum) => {
    // If the delete modal opens create an object with the corporation id
    // and the loh policynumber to delete the right item.
    const item = {
      corporation_id: datum.corporation.id,
      loh_pnr: props.loh.policy_number,
    };
    // Set the item.
    setItem(item);
    // Open the delete modal.
    setShowDel(true);
  };

  return (
    <>
      <Modal centered show={props.show} onHide={props.onHide}>
        <Modal.Header className={styles.modalHeader} closeVariant="white">
          <Modal.Title>{modalTitleAddCorp}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4>Gesellschaft für Kasko {policy_number}</h4>
          <LohCorporationForm
            btnText={isEditing ? "Bearbeiten" : "Hinzufügen"}
            onSubmit={handleAddAndEdit}
            editData={lohCorp}
            isEditing={props.isEditing}
          />
          <div className={styles.totalShare}>
            <h6>Gesamtanteil: {totalShare} %</h6>
            <h6>Fehlender Anteil: {missingShare} %</h6>
          </div>
          <div className={styles.tableContainer}>
            <Table
              headers={headers}
              header={header}
              items={corporations}
              attr={{ 0: "name" }}
              tableStyle="tableContainerModal"
              onEdit={handleEdit}
              onDelete={handleShowDel}
              delete
              signArray={lohCorpSignArray}
              edit
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {props.isEditing && (
            <Button variant="secondary" onClick={props.onBack}>
              Zurück
            </Button>
          )}
          <Button
            variant="secondary"
            onClick={props.onClose}
            disabled={totalShare === 100 ? false : true}
          >
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>

      <CustomDeleteModal
        modalTitle={modalTitleDel}
        modalBody={modalBodyDel}
        onClose={handleCloseDel}
        onDelete={handleDelete}
        onHide={handleCloseDel}
        show={showDel}
      />
      <GenericAlert
        show={show}
        alertVariant={alertContent.variant}
        alertHeading={alertContent.heading}
        alertBody={alertContent.message}
      />
    </>
  );
};

export default CustomLohCorporationModal;