import React, { useEffect, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import { Formik } from "formik";
import * as Yup from "yup";
import styles from "./LohForm.module.scss";
import {
  pricePattern,
  percentagePattern,
  percentagePatternPremium,
} from "../../patterns/patterns";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";
import LohDataService from "../../services/loh.data.service";
import { fillOutFieldsWithDivisonData } from "../../helper/helper";

const LohChangeForm = (props) => {
  // The data to be displayed in the textfields.
  const data = props.editData;

  // The states for select fileds (policynumber)
  const [policynumber, setPolicynumber] = useState([]);

  const getAllPolicynumbers = () => {
    LohDataService.getAllCurrent()
      .then((response) => {
        let policynumbers = [];
        response.data.forEach((element) => {
          console.log(element);
          policynumbers.push({
            value: element,
            label: element,
          });
        });
        console.log(policynumbers);
        setPolicynumber(policynumbers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllPolicynumbers() function the first time this component
  // is rendered.
  useEffect(() => {
    getAllPolicynumbers();
  }, []);

  // The object schema to validate the input fields.
  const LohSchema = Yup.object().shape({
    policy_number: Yup.object().required("Pflichtfeld").nullable(),
    daily_rate: Yup.string()
      .required("Pflichtfeld")
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      ),
    payment_method: Yup.string().nullable(),
    deductible: Yup.number()
      .required("Pflichtfeld")
      .min(0, "Die SB muss größer oder gleich 0 sein.")
      .max(365, "Die SB darf den Wert von 365 nicht überschreiten."),
    deductible_machine: Yup.number()
      .nullable()
      .min(0, "Die SB muss größer oder gleich 0 sein.")
      .max(365, "Die SB darf den Wert von 365 nicht überschreiten."),
    hull: Yup.number()
      .required("Pflichtfeld")
      .min(0, "Die Kasko muss größer oder gleich 0 sein.")
      .max(365, "Die Kasko darf den Wert von 365 nicht überschreiten."),
    machine: Yup.number()
      .required("Pflichtfeld")
      .min(0, "Die Maschine muss größer oder gleich 0 sein.")
      .max(365, "Die Maschine darf den Wert von 365 nicht überschreiten."),
    tax: Yup.string()
      .required("Pflichtfeld")
      .matches(
        percentagePattern,
        "Mit Komma trennen maximal 2 Nachkommastellen"
      ),
    insurance_start: Yup.date().required("Pflichtfeld"),
    brokerage: Yup.string()
      .required("Pflichtfeld")
      .matches(
        percentagePattern,
        "Mit Komma trennen maximal 2 Nachkommastellen"
      ),
    ship: Yup.object().required("Pflichtfeld").nullable(),
    fifty_one_sum: Yup.string()
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      )
      .nullable(),
    insurance_period_from: Yup.date().required("Pflichtfeld"),
    insurance_period_to: Yup.date().required("Pflichtfeld"),
    premimu_rate: Yup.string()
        .required("Pflichtfeld")
        .matches(
          percentagePatternPremium,
          "Mit Komma trennen und maximal 4 Nachkommastellen"
      ),
  });

  return (
    <Formik
      validationSchema={LohSchema}
      onSubmit={(values) => {
        props.onSubmit(values);
      }}
      initialValues={{
        policy_number:
          data !== undefined && data.policy_number !== null
            ? { value: data.policy_number, label: data.policy_number }
            : "",
        daily_rate: data !== undefined ? data.daily_rate : "",
        payment_method: data !== undefined ? data.payment_method : "",
        deductible: data !== undefined ? data.deductible : "",
        deductible_machine:
          data !== undefined && data.deductible_machine !== null
            ? data.deductible_machine
            : "",
        hull: data !== undefined ? data.hull : "",
        machine: data !== undefined ? data.machine : "",
        tax: data !== undefined ? data.tax : "",
        insurance_start: data !== undefined ? data.insurance_start : "",
        termination:
          data !== undefined && data.termination !== null
            ? data.termination
            : "",
        brokerage: data !== undefined ? data.brokerage : "",
        ship: data !== undefined ? data.ship : "",
        fifty_one_sum:
          data !== undefined && data.fifty_one_sum !== null
            ? data.fifty_one_sum
            : "",
        insurance_period_from:
          data !== undefined ? data.insurance_period_from : "",
        insurance_period_to: data !== undefined ? data.insurance_period_to : "",
        premium_rate: data !== undefined ? data.premium_rate : "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        errors,
        values,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridHull">
              <Form.Label>Policennummer</Form.Label>
              <Select
                isClearable
                isSearchable
                id="policy_number"
                name="policy_number"
                placeholder="Wähle Policennummer..."
                onChange={(selected) => {
                  if (selected !== null) {
                    LohDataService.get(selected.value)
                      .then((response) => {
                        console.log(response.data);
                        const loh = response.data;
                        fillOutFieldsWithDivisonData(
                          loh,
                          values,
                          setFieldValue
                        );
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                  values.policy_number = selected;
                  setFieldValue("policy_number", selected);
                }}
                onBlur={handleBlur}
                onMenuClose={() =>
                  setFieldTouched("policy_number", true, false)
                }
                options={policynumber}
                value={values.policy_number}
                styles={chooseCustomSelectStyle(
                  errors.policy_number,
                  values.policy_number,
                  touched.policy_number
                )}
              />
              {errors.policy_number && touched.policy_number ? (
                <div className={styles["invalid-error"]}>
                  {errors.policy_number}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row>
          <Form.Group as={Col} controlId="formGridPremiumRate">
                <Form.Label>Prämiensatz Kunde</Form.Label>
                <Form.Control
                  name="premium_rate"
                  type="text"
                  placeholder="Prämiensatz"
                  value={values.premium_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("premium_rate", true, false)}
                  isValid={touched.premium_rate && !errors.premium_rate}
                  isInvalid={!!errors.premium_rate && touched.premium_rate}
                />
                {errors.premium_rate && touched.premium_rate ? (
                  <div className={styles["invalid-error"]}>
                    {errors.premium_rate}
                  </div>
                ) : null}
              </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridDailyRate">
              <Form.Label>Tagessatz</Form.Label>
              <Form.Control
                name="daily_rate"
                type="text"
                placeholder="Tagessatz"
                value={values.daily_rate}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("daily_rate", true, false)}
                isValid={touched.daily_rate && !errors.daily_rate}
                isInvalid={!!errors.daily_rate && touched.daily_rate}
              />
              {errors.daily_rate && touched.daily_rate ? (
                <div className={styles["invalid-error"]}>
                  {errors.daily_rate}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridDeductible">
              <Form.Label>Selbstbeteiligung</Form.Label>
              <Form.Control
                name="deductible"
                type="number"
                placeholder="Selbstbeteiligung"
                value={values.deductible}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("deductible", true, false)}
                isValid={touched.deductible && !errors.deductible}
                isInvalid={!!errors.deductible && touched.deductible}
              />
              {errors.deductible && touched.deductible ? (
                <div className={styles["invalid-error"]}>
                  {errors.deductible}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridDeductible">
              <Form.Label>SB Machine</Form.Label>
              <Form.Control
                name="deductible_machine"
                type="number"
                placeholder="SB Machine"
                value={values.deductible_machine}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() =>
                  setFieldTouched("deductible_machine", true, false)
                }
                isValid={
                  touched.deductible_machine && !errors.deductible_machine
                }
                isInvalid={
                  !!errors.deductible_machine && touched.deductible_machine
                }
              />
              {errors.deductible_machine && touched.deductible_machine ? (
                <div className={styles["invalid-error"]}>
                  {errors.deductible_machine}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridHull">
              <Form.Label>Max.</Form.Label>
              <Form.Control
                name="hull"
                type="number"
                placeholder="Max."
                value={values.hull}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("hull", true, false)}
                isValid={touched.hull && !errors.hull}
                isInvalid={!!errors.hull && touched.hull}
              />
              {errors.hull && touched.hull ? (
                <div className={styles["invalid-error"]}>{errors.hull}</div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridMachine">
              <Form.Label>Max. Jahr</Form.Label>
              <Form.Control
                name="machine"
                type="number"
                placeholder="Max. Jahr"
                value={values.machine}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("machine", true, false)}
                isValid={touched.machine && !errors.machine}
                isInvalid={!!errors.machine && touched.machine}
              />
              {errors.machine && touched.machine ? (
                <div className={styles["invalid-error"]}>{errors.machine}</div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridTax">
              <Form.Label>Steuer</Form.Label>
              <Form.Control
                name="tax"
                type="text"
                placeholder="Steuer"
                value={values.tax}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("tax", true, false)}
                isValid={touched.tax && !errors.tax}
                isInvalid={!!errors.tax && touched.tax}
              />
              {errors.tax && touched.tax ? (
                <div className={styles["invalid-error"]}>{errors.tax}</div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridInsuranceStart">
                <Form.Label>Versicherungsbeginn</Form.Label>
                <Form.Control
                  name="insurance_start"
                  type="date"
                  placeholder="Versicherungsbeginn"
                  value={values.insurance_start}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_start", true, false)
                  }
                  isValid={touched.insurance_start && !errors.insurance_start}
                  isInvalid={
                    !!errors.insurance_start && touched.insurance_start
                  }
                  disabled={props.isEditing}
                />
                {errors.insurance_start && touched.insurance_start ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_start}
                  </div>
                ) : null}
              </Form.Group>
          </Row>
          <div className={styles.submitBtn}>
            <SubmitButton className="submitBtn" variant="primary" type="submit">
              {props.btnText}
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LohChangeForm;
