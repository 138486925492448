import React, { useState, useEffect, useCallback } from "react";
import HullDataService from "../../services/hull.data.service";
import Header from "../Header/Header";
import CrudContentHull from "../CrudContent/CrudContentHull";
import HullForm from "./HullForm";
import HullCorporationDataService from "../../services/hull-corporation.data.service";
import { dateFormatterDE } from "../../formatter/date-formatter";
import InvoiceForm from "../Invoice/InvoiceForm";
import InvoiceDataService from "../../services/invoice.data.service";
import useAlert from "../../hooks/use-alert";
import GenericAlert from "../Alert/GenericAlert";
import HullChangeForm from "./HullChangeForm";
import { checkIfItemIsStringAndReplaceComma } from "../../helper/helper";

// Header to be displayed in the table.
export const hullHeaders = [
  "Policennummer:",
  "Versicherungssumme:",
  "Prämiensatz:",
  "Prämie:",
  "Beginn:",
  "VZ:",
  "Zahlungsmethode:",
  "Kasko-SB:",
  "Fest-SB:",
  "MSB:",
  "TPL:",
  "Steuer 1:",
  "Steuer 2:",
  "Courtage:",
  "Schiff:",
  "Versicherungssteuerfrei:",
  "Kündigung zum:",
  "Wrack-Policennummer:",
  "Wrack-Versicherungssumme:",
  "Wrack-Rate:",
  "Wrack-51:",
  "Kollisions-Policennummer:",
  "Kollisions-Versicherungssumme:",
  "Kollisions-Rate:",
  "Kollisions-51:",
  "Minen-Policennummer:",
  "Minen-Versicherungssumme:",
  "Minen-Rate:",
  "Minen-51:",
  "Effekten-Policennummer:",
  "Effekten-Versicherungssumme:",
  "Effekten-Rate:",
  "Effekten-51:",
  "Erw.-Kasko-Policennummer:",
  "Erw.-Kasko-Versicherungssumme:",
  "Erw.-Kasko-Rate:",
  "Erw.-Kasko-51:",
  "51:",
  "Versicherungszeitraum von:",
  "Versicherungszeitraum bis:",
];

// Array to set currency or percentage sign after specific values.
export const hullSignArray = [
  "",
  "€",
  "%",
  "€",
  "",
  "",
  "",
  "€",
  "€",
  "€",
  "€",
  "%",
  "%",
  "%",
  "",
  "",
  "",
  "",
  "€",
  "%",
  "€",
  "",
  "€",
  "%",
  "€",
  "",
  "€",
  "%",
  "€",
  "",
  "€",
  "%",
  "€",
  "",
  "€",
  "%",
  "€",
  "€",
  "",
  "",
];

const Hull = () => {
  // The state which holds all hulls.
  const [allHulls, setAllHulls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const { show, alertContent, handleAlertVisible } = useAlert();
  const [hull, setHull] = useState({});
  const [dueDateIsDisabled, setDueDateIsDisabled] = useState(false);

  // Header to be displayed in the table.
  const header = [
    "Policennummer",
    "Versicherungssumme",
    "Prämiensatz",
    "Prämie",
    "Beginn",
    "VZ",
    "ZW",
    "Kasko-SB",
    "Maschinen-SB",
    "MSB",
    "TPL",
    "Steuer 1",
    "Steuer 2",
    "Courtage",
    "Schiff",
    "VS frei",
    "Kündigung",
    "Bearbeiten",
  ];

  const modalHeader = ["Gesellschaft", "Anteil", "Prämiensatz"];

  // The variables for the modals (add, delete, edit)
  const modalTitle = "Kasko löschen";
  const modalTitleEdit = "Kasko bearbeiten";
  const modalTitleAdd = "Kasko hinzufügen";
  const modalBody = "Möchten Sie die Kasko wirklich löschen?";

  const initialHullObject = {
    policy_number: "",
    insurance_sum: "",
    insurance_start: "",
    vz: "",
    payment_method: "",
    hull_liability: "",
    machine_franchise_fixed: "",
    msb: "",
    tpl: "",
    tax: "",
    tax_two: "",
    brokerage: "",
    ship: "",
    tax_free: "",
    termination: "",
    wreck_policy_number: "",
    wreck_sum: "",
    wreck_rate: "",
    wreck_fifty_one_sum: "",
    collision_policy_number: "",
    collision_liability_sum: "",
    collision_liability_rate: "",
    collision_fifty_one_sum: "",
    minen_policy_number: "",
    minen_sum: "",
    minen_rate: "",
    minen_fifty_one_sum: "",
    effects_policy_number: "",
    effects_sum: "",
    effects_rate: "",
    effects_fifty_one_sum: "",
    extended_policy_number: "",
    extended_hull_sum: "",
    extended_hull_rate: "",
    extended_fifty_one_sum: "",
    fifty_one_sum_result: "",
    insurance_period_from: "",
    insurance_period_to: "",
  };

  // The data to be edited
  const [editData, setEditData] = useState(initialHullObject);

  // The data to be displayed
  const [displayData, setDisplayData] = useState({
    array: [],
    tableData: [],
  });

  // The filter value out of the filter input on top of the table
  const [filter, setFilter] = useState({ filter: "", year: "" });

  // Count of header items that need to be skipped
  const skip = 1;

  // Function to get all hulls out of the database.
  const getAllHulls = useCallback(() => {
    console.log(filter);
    HullDataService.getAll(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          console.log(response.data);
          // Array which holds all hulls.
          var hull = [];
          response.data.forEach((element) => {
            hull.push({
              policy_number: element.policy_number,
              insurance_sum: element.insurance_sum.toString().replace(".", ","),
              premium_rate: element.premium_rate.toString().replace(".", ","),
              premium: element.premium.toString().replace(".", ","),
              insurance_start: element.insurance_start,
              vz: element.vz,
              payment_method: element.payment_method,
              hull_liability:
                element.hull_liability !== null
                  ? element.hull_liability.toString().replace(".", ",")
                  : null,
              machine_franchise_fixed:
                element.machine_franchise_fixed !== null
                  ? element.machine_franchise_fixed.toString().replace(".", ",")
                  : null,
              msb:
                element.msb !== null
                  ? element.msb.toString().replace(".", ",")
                  : null,
              tpl:
                element.tpl !== null
                  ? element.tpl.toString().replace(".", ",")
                  : null,
              tax:
                element.tax !== null
                  ? element.tax.toString().replace(".", ",")
                  : null,
              tax_two:
                element.tax_two !== null
                  ? element.tax_two.toString().replace(".", ",")
                  : null,
              brokerage: element.brokerage.toString().replace(".", ","),
              ship: element.ship,
              tax_free: element.tax_free,
              termination: element.termination,
              wreck_policy_number: element.wreck_policy_number,
              wreck_sum:
                element.wreck_sum !== null
                  ? element.wreck_sum.toString().replace(".", ",")
                  : null,
              wreck_rate:
                element.wreck_rate !== null
                  ? element.wreck_rate.toString().replace(".", ",")
                  : null,
              wreck_fifty_one_sum:
                element.wreck_fifty_one_sum !== null
                  ? element.wreck_fifty_one_sum.toString().replace(".", ",")
                  : null,
              collision_policy_number: element.collision_policy_number,
              collision_liability_sum:
                element.collision_liability_sum !== null
                  ? element.collision_liability_sum.toString().replace(".", ",")
                  : null,
              collision_liability_rate:
                element.collision_liability_rate !== null
                  ? element.collision_liability_rate
                      .toString()
                      .replace(".", ",")
                  : null,
              collision_fifty_one_sum:
                element.collision_fifty_one_sum !== null
                  ? element.collision_fifty_one_sum.toString().replace(".", ",")
                  : null,
              minen_policy_number: element.minen_policy_number,
              minen_sum:
                element.minen_sum !== null
                  ? element.minen_sum.toString().replace(".", ",")
                  : null,
              minen_rate:
                element.minen_rate !== null
                  ? element.minen_rate.toString().replace(".", ",")
                  : null,
              minen_fifty_one_sum:
                element.minen_fifty_one_sum !== null
                  ? element.minen_fifty_one_sum.toString().replace(".", ",")
                  : null,
              effects_policy_number: element.effects_policy_number,
              effects_sum:
                element.effects_sum !== null
                  ? element.effects_sum.toString().replace(".", ",")
                  : null,
              effects_rate:
                element.effects_rate !== null
                  ? element.effects_rate.toString().replace(".", ",")
                  : null,
              effects_fifty_one_sum:
                element.effects_fifty_one_sum !== null
                  ? element.effects_fifty_one_sum.toString().replace(".", ",")
                  : null,
              extended_policy_number: element.extended_policy_number,
              extended_hull_sum:
                element.extended_hull_sum !== null
                  ? element.extended_hull_sum.toString().replace(".", ",")
                  : null,
              extended_hull_rate:
                element.extended_hull_rate !== null
                  ? element.extended_hull_rate.toString().replace(".", ",")
                  : null,
              extended_fifty_one_sum:
                element.extended_fifty_one_sum !== null
                  ? element.extended_fifty_one_sum.toString().replace(".", ",")
                  : null,
              fifty_one_sum_result:
                element.fifty_one_sum_result !== null
                  ? element.fifty_one_sum_result.toString().replace(".", ",")
                  : null,
              insurance_period_from: element.insurance_period_from,
              insurance_period_to: element.insurance_period_to,
            });
            console.log(element.vz);
          });

          // Set all hulls state with the created hull array.
          setAllHulls(hull);
        } else {
          // Set the all hulls to its default value.
          setAllHulls([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  // Call getAllHulls() everytime the filter value changes.
  useEffect(() => {
    getAllHulls();
  }, [getAllHulls]);

  // Function to delete an hull object out of the database.
  // Accepts an hull object to get its policynumber.
  const deleteItem = (item) => {
    HullDataService.delete(item.policy_number)
      .then((response) => {
        console.log(response);
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Die Kaskoversicherung "' +
            item.policy_number +
            '" wurde erfolgreich gelöscht!',
          "success"
        );
        getAllHulls();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Kaskoversicherung "' +
            item.policy_number +
            '" konnte nicht gelöscht werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Replace all commas with dots.
  // Must be done for all decimal values because
  // the backend only accepts data with a dot.
  const replaceComma = (item) => {
    item.insurance_sum = checkIfItemIsStringAndReplaceComma(item.insurance_sum);
    item.hull_liability = checkIfItemIsStringAndReplaceComma(
      item.hull_liability
    );
    item.machine_franchise_fixed = checkIfItemIsStringAndReplaceComma(
      item.machine_franchise_fixed
    );
    item.msb = checkIfItemIsStringAndReplaceComma(item.msb);
    item.tpl = checkIfItemIsStringAndReplaceComma(item.tpl);
    item.tax = checkIfItemIsStringAndReplaceComma(item.tax);
    item.tax_two = checkIfItemIsStringAndReplaceComma(item.tax_two);
    item.brokerage = checkIfItemIsStringAndReplaceComma(item.brokerage);
    item.wreck_sum = checkIfItemIsStringAndReplaceComma(item.wreck_sum);
    item.wreck_rate = checkIfItemIsStringAndReplaceComma(item.wreck_rate);
    item.wreck_fifty_one_sum = checkIfItemIsStringAndReplaceComma(item.wreck_fifty_one_sum);
    item.collision_liability_sum = checkIfItemIsStringAndReplaceComma(
      item.collision_liability_sum
    );
    item.collision_liability_rate = checkIfItemIsStringAndReplaceComma(
      item.collision_liability_rate
    );
    item.collision_fifty_one_sum = checkIfItemIsStringAndReplaceComma(
      item.collision_fifty_one_sum
    );
    item.minen_sum = checkIfItemIsStringAndReplaceComma(item.minen_sum);
    item.minen_rate = checkIfItemIsStringAndReplaceComma(item.minen_rate);
    item.minen_fifty_one_sum = checkIfItemIsStringAndReplaceComma(item.minen_fifty_one_sum);
    item.effects_sum = checkIfItemIsStringAndReplaceComma(item.effects_sum);
    item.effects_rate = checkIfItemIsStringAndReplaceComma(item.effects_rate);
    item.effects_fifty_one_sum = checkIfItemIsStringAndReplaceComma(item.effects_fifty_one_sum);
    item.extended_hull_sum = checkIfItemIsStringAndReplaceComma(
      item.extended_hull_sum
    );
    item.extended_hull_rate = checkIfItemIsStringAndReplaceComma(
      item.extended_hull_rate
    );
    item.extended_fifty_one_sum = checkIfItemIsStringAndReplaceComma(
      item.effects_fifty_one_sum
    );
    item.premium = checkIfItemIsStringAndReplaceComma(item.premium);
    item.premium_rate = checkIfItemIsStringAndReplaceComma(item.premium_rate);
  };

  const selectValuesFromObjects = (item) => {
    if (item.policy_number.value !== undefined) {
      item.policy_number = item.policy_number.value;
    }
    if (item.ship.value !== undefined) {
      item.ship = item.ship.value;
    }
  };

  // Function to edit a specific hull item.
  const editItem = (item) => {
    replaceComma(item);
    selectValuesFromObjects(item);
    HullDataService.update(item.policy_number, item)
      .then((response) => {
        let hull = response.data;
        console.log(response);
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Die Kaskoversicherung "' +
            item.policy_number +
            '" wurde erfolgreich bearbeitet!',
          "success"
        );
        setHull(hull);
        setEditData(item);
        getAllHulls();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Kaskoversicherung "' +
            item.policy_number +
            '" konnte nicht bearbeitet werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to create a new hull item in the database.
  const addItem = (item) => {
    console.log(item);
    selectValuesFromObjects(item);
    replaceComma(item);
    HullDataService.create(item)
      .then((response) => {
        let hull = response.data;
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Die Kaskoversicherung "' +
            hull.policy_number +
            '" wurde erfolgreich erstellt!',
          "success"
        );
        getAllHulls();
        setHull(hull);
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          "Die Kaskoversicherung konnte nicht erstellt werden!",
          "danger"
        );
        console.log(error);
      });
  };

  // Function to set the current filter value.
  const handleFilter = (filterValue) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, filter: filterValue.filter };
    });
  };

  // Function to set the current edit data.
  const handleEditItem = (item) => {
    const data = { ...item };
    // Change the vz value to a boolean value.
    console.log(data);
    setEditData(data);
  };

  const getAllCorporations = (item) => {
    HullCorporationDataService.getAll({ filter: item.policy_number })
      .then((response) => {
        if (response.data.length !== 0) {
          // Array which holds the specific hull-corps.
          var hullCorp = [];
          response.data.forEach((element) => {
            console.log(element.hull);
            hullCorp.push({
              corporation: element.corporation,
              share: element.share,
              premium_rate: element.premium_rate,
              hull: element.hull,
            });
          });
          createDisplayDataItem(hullCorp, item);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to create the display data item.
  const createDisplayDataItem = (hullCorp, item) => {
    console.log(item.fifty_one_sum_result);
    // Create an array which holds multiple arrays each with
    // one key value pair separated by a comma.
    let hullArray = Object.entries(item);
    for (let i = 0; i < hullHeaders.length; i++) {
      // Replace all keys with the header value.
      hullArray[i][0] = hullHeaders[i];
      hullArray[i][2] = hullSignArray[i];
    }
    console.log(hullArray);
    // Set the displayed data.
    setDisplayData({
      array: hullArray,
      tableData: hullCorp,
    });
  };

  // Function which creates an array with the data to be displayed
  // in the more information view.
  const handleDisplayItem = (data) => {
    // Only get one attribute of the ship object.
    const item = { ...data };
    item.ship = item.ship.shipname;
    item.insurance_start = dateFormatterDE(item.insurance_start);
    item.termination = dateFormatterDE(item.termination);
    item.insurance_period_from = dateFormatterDE(item.insurance_period_from);
    item.insurance_period_to = dateFormatterDE(item.insurance_period_to);
    item.vz = item.vz ? "Ja" : "Nein";
    item.tax_free = item.tax_free ? "Ja" : "Nein";
    console.log(item.policy_number);

    getAllCorporations(item);
  };

  const handleYearChange = (year) => {
    console.log(year);
    setFilter((prevFilter) => {
      return { ...prevFilter, year: year };
    });
  };

  const handleInvoiceCreation = (item) => {
    HullDataService.get(item.div.policy_number)
      .then((response) => {
        const invoiceData = {
          hull: response.data,
          dueDatesList: item.dueDatesList,
        };
        console.log(invoiceData);
        InvoiceDataService.generateHullInvoice(invoiceData)
          .then((response) => {
            handleAlertVisible(
              "Erfolgreiche Rechnungsgenerierung",
              "Die Kaskorechnung wurde erfolgreich generiert!",
              "success"
            );
            console.log(response);
          })
          .catch((error) => {
            handleAlertVisible(
              "Fehler",
              "Die Kaskorechnung konnte nicht generiert werden!",
              "danger"
            );
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setDisabled = (isDisabled) => {
    setDueDateIsDisabled(isDisabled);
  }

  return (
    <main>
      <Header />
      <div className="main">
        <CrudContentHull
          heading="Kasko"
          items={allHulls}
          header={header}
          onDelete={deleteItem}
          delete
          modalTitle={modalTitle}
          modalBody={modalBody}
          modalTitleEdit={modalTitleEdit}
          form={<HullForm editData={editData} btnText="Bearbeiten" isEditing />}
          onEditItemReceive={handleEditItem}
          onEditItem={editItem}
          placeholder={"Filter Kasko..."}
          modalTitleAdd={modalTitleAdd}
          formAdd={<HullForm btnText="Hinzufügen" />}
          onAddItem={addItem}
          add
          onFilter={handleFilter}
          skip={skip}
          attr={{ 14: "shipname" }}
          onDisplayItemReceive={handleDisplayItem}
          displayItems={displayData}
          moreInfo
          signArray={hullSignArray}
          edit
          modalHeader={modalHeader}
          modalHeaders={modalHeader}
          onYearChange={handleYearChange}
          hasYearFilter
          onInvoiceCreation={handleInvoiceCreation}
          invoice
          dueDatesForm={
            <InvoiceForm btnText="Manuelle Fälligkeiten anwenden" isDisabled={dueDateIsDisabled} />
          }
          dueDatesIsDisabled={setDisabled}
          invoiceModalHeader="Kasko Rechungsgenerierung"
          loading={isLoading}
          unauthorized={isUnauthorized}
          error={error}
          onChangeItem={addItem}
          formChange={<HullChangeForm btnText="Hinzufügen" />}
          hull={hull}
          changeTitle="Kasko Änderung hinzufügen"
        />
      </div>
      <GenericAlert
        show={show}
        alertVariant={alertContent.variant}
        alertHeading={alertContent.heading}
        alertBody={alertContent.message}
      />
    </main>
  );
};

export default Hull;
