import React, { useState, useEffect, useCallback } from "react";
import HullFranchiseDataService from "../../services/hull-franchise.data.service";
import HullDataService from "../../services/hull.data.service";
import InterestDataService from "../../services/interest.data.service";
import InsuredObjectDataService from "../../services/insured.object.data.service";
import LohDataService from "../../services/loh.data.service";
import styles from "./OverviewShipData.module.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SelectYear from "../UI/SelectYear/SelectYear";
import { hullSignArray, hullHeaders } from "../Hull/Hull";
import {
  hullFranchiseHeaders,
  hullFranchiseSignArray,
} from "../Hull/HullFranchise";
import { interestHeaders, interestSignArray } from "../Interest/Interest";
import { lohHeaders, lohSignArray } from "../Loh/Loh";
import LohFranchiseDataService from "../../services/loh-franchise.data.service";
import {
  lohFranchiseHeaders,
  lohFranchiseSignArray,
} from "../Loh/LohFranchise";
import ElectronicsInsuranceDataService from "../../services/electronics-insurance.data.service";
import {
  electronicsInsuranceHeaders,
  electronicsInsuranceSignArray,
} from "../ElectronicsInsurance/ElectronicsInsurance";
import { pAndIHeaders, pAndISignArray } from "../PAndI/PAndI";
import PAndIDataService from "../../services/p-and-i.data.service";
import HullCorporationDataService from "../../services/hull-corporation.data.service";
import PAndIFranchiseDataService from "../../services/p-and-i-franchise.data.service";
import Select from "react-select";
import { dateFormatterDE } from "../../formatter/date-formatter";
import { compareHull, compareLoh, comparePAndI } from "../../helper/helper";
import { createArrayInsideArray } from "../../helper/helper";
import lohCorporationDataService from "../../services/loh-corporation.data.service";

const OverViewShipData = (props) => {
  const [ships, setShips] = useState([]);
  const [ship, setShip] = useState({});
  const [year, setYear] = useState("");

  const handleShipSelectionChange = (ship) => {
    if (ship !== null) {
      setShip(ship.value);
      props.onShipSelected(true);
    }
  };

  const handleYearChange = (year) => {
    console.log(year);
    setYear(year);
    props.onYearSelected(true);
  };

  const { onUnauthorized } = props;

  const getAllShips = useCallback(() => {
    InsuredObjectDataService.getAll({ filter: "" })
      .then((response) => {
        console.log(response.data);
        let ships = [];
        response.data.forEach((element) => {
          ships.push({ value: element, label: element.shipname });
        });
        setShips(ships);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            onUnauthorized(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, [onUnauthorized]);

  useEffect(() => {
    getAllShips();
  }, [getAllShips]);

  const { onHullCorpFetched } = props;

  const getSpecificHullCorporations = useCallback(
    async (policy_number) => {
      HullCorporationDataService.getAll({ filter: policy_number })
        .then((response) => {
          if (response.data.length !== 0) {
            // Array which holds the specific hull-corps.
            var hullCorp = [];
            response.data.forEach((element) => {
              hullCorp.push({
                corporation: element.corporation,
                share: element.share,
                premium_rate: element.premium_rate,
                hull: element.hull,
              });
            });
            hullCorp.sort(compareHull);
            onHullCorpFetched(createArrayInsideArray(hullCorp, "hull"));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [onHullCorpFetched]
  );

  const { onHullDataInserted } = props;

  const resetHullData = useCallback(() => {
    onHullDataInserted([]);
  }, [onHullDataInserted]);

  const getSpecificHull = useCallback(
    async (ship, year) => {
      console.log(ship);
      if (Object.keys(ship).length !== 0 && year !== "") {
        const policy_number =
          "10." + ship.id + "." + ship.policyholder.id + "." + year;
        HullDataService.getAll({ filter: policy_number, year: year })
          .then((response) => {
            //console.log(response.data)
            const hull = response.data;
            let hullArray = [];
            hull.forEach((element) => {
              hullArray.push({
                name: "Kasko-Versicherung " + element.policy_number,
                division: {
                  policy_number: element.policy_number,
                  insurance_sum: element.insurance_sum,
                  premium: element.premium,
                  premium_rate: element.premium_rate,
                  insurance_start: dateFormatterDE(element.insurance_start),
                  vz: element.vz ? "Ja" : "Nein",
                  payment_method: element.payment_method,
                  hull_liability: element.hull_liability,
                  machine_franchise_fixed: element.machine_franchise_fixed,
                  msb: element.msb,
                  tpl: element.tpl,
                  tax: element.tax,
                  tax_two: element.tax_two,
                  brokerage: element.brokerage,
                  ship: element.ship.shipname,
                  tax_free: element.tax_free ? "Ja" : "Nein",
                  termination: dateFormatterDE(element.termination),
                  wreck_policy_number: element.wreck_policy_number,
                  wreck_sum: element.wreck_sum,
                  wreck_rate: element.wreck_rate,
                  wreck_fifty_one_sum: element.wreck_fifty_one_sum,
                  collision_policy_number: element.collision_policy_number,
                  collision_liability_sum: element.collision_liability_sum,
                  collision_liability_rate: element.collision_liability_rate,
                  collision_fifty_one_sum: element.collision_fifty_one_sum,
                  minen_policy_number: element.minen_policy_number,
                  minen_sum: element.minen_sum,
                  minen_rate: element.minen_rate,
                  minen_fifty_one_sum: element.minen_fifty_one_sum,
                  effects_policy_number: element.effects_policy_number,
                  effects_sum: element.effects_sum,
                  effects_rate: element.effects_rate,
                  effects_fifty_one_sum: element.effects_fifty_one_sum,
                  extended_policy_number: element.extended_policy_number,
                  extended_hull_sum: element.extended_hull_sum,
                  extended_hull_rate: element.extended_hull_rate,
                  extended_fifty_one_sum: element.extended_fifty_one_sum,
                  fifty_one_sum_result: element.fifty_one_sum_result,
                  insurance_period_from: dateFormatterDE(
                    element.insurance_period_from
                  ),
                  insurance_period_to: dateFormatterDE(
                    element.insurance_period_to
                  ),
                },
                headers: hullHeaders,
                signs: hullSignArray,
              });
            });
            console.log(hullArray);
            console.log(hullHeaders);
            onHullDataInserted(hullArray);
            getSpecificHullCorporations(policy_number);
          })
          .catch((error) => {
            resetHullData();
          });
      } else {
        resetHullData();
      }
    },
    [onHullDataInserted, getSpecificHullCorporations, resetHullData]
  );

  const { onHullFranchiseDataInserted } = props;

  const resetHullFranchiseData = useCallback(() => {
    onHullFranchiseDataInserted([]);
  }, [onHullFranchiseDataInserted]);

  const getSpecificHullFranchise = useCallback(
    (ship, year) => {
      if (Object.keys(ship).length !== 0 && year !== "") {
        const policy_number =
          "50." + ship.id + "." + ship.policyholder.id + "." + year;
        HullFranchiseDataService.getAll({ filter: policy_number, year: year })
          .then((response) => {
            const hullFranchise = response.data;
            console.log("Response data: " + response.data);
            let hullFranchiseArray = [];
            hullFranchise.forEach((element) => {
              hullFranchiseArray.push({
                name: "Kasko-Franchise-Versicherung " + element.policy_number,
                division: {
                  policy_number: element.policy_number,
                  hull: element.hull.policy_number,
                  fixed_premium: element.fixed_premium,
                  fixed_premium_corp: element.fixed_premium_corp,
                  insurance_start: dateFormatterDE(element.insurance_start),
                  payment_method: element.payment_method,
                  tax: element.tax,
                  brokerage: element.brokerage,
                  ship: element.ship.shipname,
                  corporation: element.corporation.name,
                  termination: dateFormatterDE(element.termination),
                  insurance_sum_one: element.insurance_sum_one,
                  premium_one: element.premium_one,
                  deductible_one: element.deductible_one,
                  insurance_sum_two: element.insurance_sum_two,
                  premium_two: element.premium_two,
                  deductible_two: element.deductible_two,
                  insurance_sum_three: element.insurance_sum_three,
                  premium_three: element.premium_three,
                  deductible_three: element.deductible_three,
                  insurance_sum_four: element.insurance_sum_four,
                  premium_four: element.premium_four,
                  deductible_four: element.deductible_four,
                  fifty_one_sum: element.fifty_one_sum,
                  insurance_period_from: dateFormatterDE(
                    element.insurance_period_from
                  ),
                  insurance_period_to: dateFormatterDE(
                    element.insurance_period_to
                  ),
                },
                headers: hullFranchiseHeaders,
                signs: hullFranchiseSignArray,
              });
            });
            onHullFranchiseDataInserted(hullFranchiseArray);
          })
          .catch((error) => {
            resetHullFranchiseData();
          });
      } else {
        resetHullFranchiseData();
      }
    },
    [onHullFranchiseDataInserted, resetHullFranchiseData]
  );

  const { onInterestDataInserted } = props;

  const resetInterestData = useCallback(() => {
    onInterestDataInserted([]);
  }, [onInterestDataInserted]);

  const getSpecificInterest = useCallback(
    (ship, year) => {
      if (Object.keys(ship).length !== 0 && year !== "") {
        const policy_number =
          "70." + ship.id + "." + ship.policyholder.id + "." + year;
        InterestDataService.getAll({ filter: policy_number, year: year })
          .then((response) => {
            const interest = response.data;
            console.log(interest);
            let interestArray = [];
            interest.forEach((element) => {
              interestArray.push({
                name: "Interesse-Versicherung " + element.policy_number,
                division: {
                  policy_number: element.policy_number,
                  hull: element.hull.policy_number,
                  payment_method: element.payment_method,
                  insurance_start: dateFormatterDE(element.insurance_start),
                  insurance_sum: element.insurance_sum,
                  premium_rate: element.premium_rate,
                  premium: element.premium,
                  tax: element.tax,
                  brokerage: element.brokerage,
                  ship: element.ship.shipname,
                  corporation: element.corporation.name,
                  premium_rate_corp: element.premium_rate_corp,
                  termination: dateFormatterDE(element.termination),
                  fifty_one_sum: element.fifty_one_sum,
                  insurance_period_from: dateFormatterDE(
                    element.insurance_period_from
                  ),
                  insurance_period_to: dateFormatterDE(
                    element.insurance_period_to
                  ),
                },
                headers: interestHeaders,
                signs: interestSignArray,
              });
            });
            console.log(interestArray);
            onInterestDataInserted(interestArray);
          })
          .catch((error) => {
            resetInterestData();
          });
      } else {
        resetInterestData();
      }
    },
    [onInterestDataInserted, resetInterestData]
  );

  const { onLohCorpFetched } = props;

  const getSpecificLohCorporations = useCallback(
    async (policy_number) => {
      lohCorporationDataService.getAll({ filter: policy_number })
        .then((response) => {
          if (response.data.length !== 0) {
            // Array which holds the specific hull-corps.
            var lohCorp = [];
            response.data.forEach((element) => {
              lohCorp.push({
                corporation: element.corporation,
                share: element.share,
                premium_rate: element.premium_rate,
                loh: element.loh,
              });
            });
            lohCorp.sort(compareLoh);
            onLohCorpFetched(createArrayInsideArray(lohCorp, "loh"));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [onLohCorpFetched]
  );

  const { onLohDataInserted } = props;

  const resetLohData = useCallback(() => {
    onLohDataInserted([]);
  }, [onLohDataInserted]);

  const getSpecificLoh = useCallback(
    (ship, year) => {
      if (Object.keys(ship).length !== 0 && year !== "") {
        const policy_number =
          "20." + ship.id + "." + ship.policyholder.id + "." + year;
        LohDataService.getAll({ filter: policy_number, year: year })
          .then((response) => {
            const loh = response.data;
            let lohArray = [];
            loh.forEach((element) => {
              lohArray.push({
                name: "Loh-Versicherung " + element.policy_number,
                division: {
                  policy_number: element.policy_number,
                  insurance_sum: element.insurance_sum,
                  daily_rate: element.daily_rate,
                  premium_rate: element.premium_rate,
                  payment_method: element.payment_method,
                  deductible: element.deductible,
                  deductible_machine: element.deductible_machine,
                  hull: element.hull,
                  machine: element.machine,
                  tax: element.tax,
                  insurance_start: dateFormatterDE(element.insurance_start),
                  brokerage: element.brokerage,
                  ship: element.ship.shipname,
                  termination: dateFormatterDE(element.termination),
                  fifty_one_sum: element.fifty_one_sum,
                  insurance_period_from: dateFormatterDE(
                    element.insurance_period_from
                  ),
                  insurance_period_to: dateFormatterDE(
                    element.insurance_period_to
                  ),
                },
                headers: lohHeaders,
                signs: lohSignArray,
              });
            });
            console.log(lohArray);
            console.log(lohHeaders);
            onLohDataInserted(lohArray);
            getSpecificLohCorporations(policy_number);
          })
          .catch((error) => {
            resetLohData();
          });
      } else {
        resetLohData();
      }
    },
    [onLohDataInserted, getSpecificLohCorporations, resetLohData]
  );

  const { onLohFranchiseDataInserted } = props;

  const resetLohFranchiseData = useCallback(() => {
    onLohFranchiseDataInserted([]);
  }, [onLohFranchiseDataInserted]);

  const getSpecificLohFranchise = useCallback(
    (ship, year) => {
      if (Object.keys(ship).length !== 0 && year !== "") {
        const policy_number =
          "60." + ship.id + "." + ship.policyholder.id + "." + year;
        LohFranchiseDataService.getAll({ filter: policy_number, year: year })
          .then((response) => {
            const lohFranchise = response.data;
            let lohFranchiseArray = [];
            lohFranchise.forEach((element) => {
              lohFranchiseArray.push({
                name: "Loh-Franchise-Versicherung " + element.policy_number,
                division: {
                  policy_number: element.policy_number,
                  loh: element.loh.policy_number,
                  fixed_premium: element.fixed_premium,
                  fixed_premium_corp: element.fixed_premium_corp,
                  daily_rate: element.daily_rate,
                  payment_method: element.payment_method,
                  deductible: element.deductible,
                  hull: element.hull,
                  machine: element.machine,
                  tax: element.tax,
                  insurance_start: dateFormatterDE(element.insurance_start),
                  brokerage: element.brokerage,
                  ship: element.ship.shipname,
                  corporation: element.corporation.name,
                  termination: dateFormatterDE(element.termination),
                  fifty_one_sum: element.fifty_one_sum,
                  insurance_period_from: dateFormatterDE(
                    element.insurance_period_from
                  ),
                  insurance_period_to: dateFormatterDE(
                    element.insurance_period_to
                  ),
                },
                headers: lohFranchiseHeaders,
                signs: lohFranchiseSignArray,
              });
            });
            onLohFranchiseDataInserted(lohFranchiseArray);
          })
          .catch((error) => {
            resetLohFranchiseData();
          });
      } else {
        resetLohFranchiseData();
      }
    },
    [onLohFranchiseDataInserted, resetLohFranchiseData]
  );

  const { onElectronicsInsuranceDataInserted } = props;

  const resetElectronicsInsuranceData = useCallback(() => {
    onElectronicsInsuranceDataInserted([]);
  }, [onElectronicsInsuranceDataInserted]);

  const getSpecificElectronicsInsurance = useCallback(
    (ship, year) => {
      if (Object.keys(ship).length !== 0 && year !== "") {
        const policy_number =
          "30." + ship.id + "." + ship.policyholder.id + "." + year;
        ElectronicsInsuranceDataService.getAll({
          filter: policy_number,
          year: year,
        })
          .then((response) => {
            const electronicsInsurance = response.data;
            let elecInsuranceArray = [];
            electronicsInsurance.forEach((element) => {
              elecInsuranceArray.push({
                name: "Elektronik-Versicherung " + element.policy_number,
                division: {
                  policy_number: element.policy_number,
                  insurance_sum: element.insurance_sum,
                  price: element.price,
                  payment_method: element.payment_method,
                  insurance_start: dateFormatterDE(element.insurance_start),
                  tax: element.tax,
                  brokerage: element.brokerage,
                  ship: element.ship.shipname,
                  corporation: element.corporation.name,
                  termination: dateFormatterDE(element.termination),
                  fifty_one_sum: element.fifty_one_sum,
                  insurance_period_from: dateFormatterDE(
                    element.insurance_period_from
                  ),
                  insurance_period_to: dateFormatterDE(
                    element.insurance_period_to
                  ),
                },
                headers: electronicsInsuranceHeaders,
                signs: electronicsInsuranceSignArray,
              });
            });
            onElectronicsInsuranceDataInserted(elecInsuranceArray);
          })
          .catch((error) => {
            resetElectronicsInsuranceData();
          });
      } else {
        resetElectronicsInsuranceData();
      }
    },
    [onElectronicsInsuranceDataInserted, resetElectronicsInsuranceData]
  );

  const { onPAndIDataInserted, onPAndIFranchFetched } = props;

  const resetPAndIData = useCallback(() => {
    onPAndIDataInserted([]);
  }, [onPAndIDataInserted]);

  const getSpecificPAndIFranchises = useCallback(
    (policy_number) => {
      PAndIFranchiseDataService.getAll({ filter: policy_number })
        .then((response) => {
          if (response.data.length !== 0) {
            // Array which holds the specific franchises.
            var franch = [];
            response.data.forEach((element) => {
              franch.push({
                franchise: element.franchise,
                amount: element.amount,
                pAndI: element.pAndI,
              });
            });
            franch.sort(comparePAndI);
            onPAndIFranchFetched(createArrayInsideArray(franch, "pAndI"));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [onPAndIFranchFetched]
  );

  const getSpecificPAndI = useCallback(
    (ship, year) => {
      if (Object.keys(ship).length !== 0 && year !== "") {
        const policy_number =
          "40." + ship.id + "." + ship.policyholder.id + "." + year;
        PAndIDataService.getAll({filter: policy_number, year: year})
          .then((response) => {
            const pAndI = response.data;
            let pAndIArray = [];
            pAndI.forEach((element) => {
              pAndIArray.push({
                name: "P & I-Versicherung " + element.policy_number,
                division: {
                  policy_number: element.policy_number,
                  fixed_premium: element.fixed_premium,
                  fixed_premium_corp: element.fixed_premium_corp,
                  insurance_start: dateFormatterDE(element.insurance_start),
                  payment_method: element.payment_method,
                  tax: element.tax_one,
                  brokerage: element.brokerage,
                  corporation: element.corporation.name,
                  ship: element.ship.shipname,
                  termination: dateFormatterDE(element.termination),
                  fifty_one_sum: element.fifty_one_sum,
                  insurance_period_from: dateFormatterDE(
                    element.insurance_period_from
                  ),
                  insurance_period_to: dateFormatterDE(
                    element.insurance_period_to
                  ),
                },
                headers: pAndIHeaders,
                signs: pAndISignArray,
              });
            });
            onPAndIDataInserted(pAndIArray);
            getSpecificPAndIFranchises(policy_number);
          })
          .catch((error) => {
            resetPAndIData();
          });
      } else {
        resetPAndIData();
      }
    },
    [onPAndIDataInserted, resetPAndIData, getSpecificPAndIFranchises]
  );

  useEffect(() => {
    getSpecificHull(ship, year);
    getSpecificHullFranchise(ship, year);
    getSpecificInterest(ship, year);
    getSpecificLoh(ship, year);
    getSpecificLohFranchise(ship, year);
    getSpecificElectronicsInsurance(ship, year);
    getSpecificPAndI(ship, year);
  }, [
    ship,
    year,
    getSpecificHull,
    getSpecificHullFranchise,
    getSpecificInterest,
    getSpecificLoh,
    getSpecificLohFranchise,
    getSpecificElectronicsInsurance,
    getSpecificPAndI,
  ]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.subheader}>
          <Select
            className={styles["ship-select"]}
            isClearable
            isSearchable
            id="ship"
            name="ship"
            placeholder="Wähle Schiff..."
            onChange={handleShipSelectionChange}
            options={ships}
          />
          <SelectYear
            className={styles["year-select"]}
            onYearChange={handleYearChange}
          />
        </div>
      </div>
    </>
  );
};

export default OverViewShipData;
