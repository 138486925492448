import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class LohCorporationDataService {
  // Get all loh corporations from the database
  getAll(filter) {
    return axios.post(API_URL + "/loh-corporations", filter, {
      headers: authHeader(),
    });
  }

  // Create a new loh corporation
  create(data) {
    console.log(data)
    return axios.post(API_URL + "/loh-corporation", data, {
      headers: authHeader(),
    });
  }

  // Delete a specific loh corporation out of the table.
  // Accepts the policynumber of the hull and the corporation id.
  delete(pnr, id) {
    return axios.delete(API_URL + `/loh-corporation/${pnr}/${id}`, {
      headers: authHeader(),
    });
  }

  // Update the data of one specific loh corporation.
  // Accepts the policynmuber of the loh, the id of the corporation 
  // and the whole loh corporation object with the changes
  update(pnr, id, data) {
    return axios.put(API_URL + `/loh-corporation/${pnr}/${id}`, data, {
      headers: authHeader(),
    });
  }
}

export default new LohCorporationDataService();
