import { useEffect, useState } from "react";
import * as Yup from "yup";
import { percentagePatternPremium, percentagePatternPremiumShare } from "../../patterns/patterns";
import CorporationDataService from "../../services/corporation.data.service";
import { multipleChoiceCorpForm } from "../Corporation/MultipleChoice/MultipleCorpChoice";

const HullCorporationForm = (props) => {
  const [hullCorpFields, setHullCorpFields] = useState({
    hull: props.editData.hull,
    corporation: "",
    share: "",
    premium_rate: "",
  });
  // Variable with the values for the input fields
  const data = props.editData;
  useEffect(() => {
    setHullCorpFields({
      hull: data.hull,
      corporation: { value: data.corporation, label: data.corporation.name },
      share: data.share,
      premium_rate: data.premium_rate,
    });
  }, [data]);

  console.log(hullCorpFields)

  const resetHullCorpFields = () => {
    setHullCorpFields({
      hull: props.editData.hull,
      corporation: "",
      share: "",
      premium_rate: "",
    });
  };

  // State object of corporations to display them in the select field.
  const [corporation, setCorporation] = useState([]);

  // Function to get all corporations out of the database.
  const getAllCorporations = () => {
    CorporationDataService.getAll({ filter: "" })
      .then((response) => {
        let corporation = [];
        response.data.forEach((element) => {
          corporation.push({ value: element, label: element.name });
        });
        setCorporation(corporation);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get all corporations if the component gets rendered the first time
  useEffect(() => {
    getAllCorporations();
  }, []);

  // Object schmema for the formik tag to validate the input fields
  const HullCorporationObjectSchema = Yup.object().shape({
    corporation: Yup.object().required("Pflichtfeld").nullable(),
    share: Yup.string()
      .required("Pflichtfeld")
      .matches(
        percentagePatternPremiumShare,
        "Mit Komma trennen und maximal 4 Nachkommstellen"
      ),
    premium_rate: Yup.string()
      .required("Pflichtfeld")
      .matches(
        percentagePatternPremium,
        "Mit Komma trennen und maximal 4 Nachkommstellen"
      ),
  });

  return (
    multipleChoiceCorpForm(HullCorporationObjectSchema, hullCorpFields, resetHullCorpFields, corporation, props.btnText, props.onSubmit)
  );
};

export default HullCorporationForm;
