export const fillOutFieldsWithDivisonData = (
  division,
  value,
  setFieldValue
) => {
  console.log(division);
  console.log(value);
  let keyObj = { ...value };
  delete keyObj.policy_number;
  let keyArray = Object.keys(keyObj);
  keyArray.forEach((element) => {
    if (division[element] !== null) {
      value[element] = division[element];
      setFieldValue(element, division[element]);
    } else {
      value[element] = "";
      setFieldValue(element, "");
    }
  });
};

export const checkIfItemIsStringAndReplaceComma = (item) => {
  return typeof item === "string" ? item.replace(/,/g, ".") : item;
};

export const checkIfItemIsStringAndNotNullAndReplaceComma = (item) => {
  return typeof item === "string" && item !== null ? item.replace(/,/g, ".") : item;
};

export const createArrayInsideArray = (arr, division) => {
  console.log(arr);
  let arrTmp = [arr[0]];
  let newArray = [];
  arr.map((element, index, array) => {
    if (array[index + 1] !== undefined) {
      if (
        element[division].policy_number ===
        array[index + 1][division].policy_number
      ) {
        arrTmp.push(array[index + 1]);
      } else {
        newArray.push(arrTmp);
        arrTmp = [];
        arrTmp = [array[index + 1]];
      }
    } else {
      newArray.push(arrTmp);
    }
    return null;
  });
  console.log(newArray);
  return newArray;
};

export function compareHull(a, b) {
  if (a.hull.policy_number < b.hull.policy_number) {
    return -1;
  }
  if (a.hull.policy_number > b.hull.policy_number) {
    return 1;
  }
  return 0;
}

export function compareLoh(a, b) {
  if (a.loh.policy_number < b.loh.policy_number) {
    return -1;
  }
  if (a.loh.policy_number > b.loh.policy_number) {
    return 1;
  }
  return 0;
}

export function comparePAndI(a, b) {
  if (a.pAndI.policy_number < b.pAndI.policy_number) {
    return -1;
  }
  if (a.pAndI.policy_number > b.pAndI.policy_number) {
    return 1;
  }
  return 0;
}

export function createSpecificDivisionObject(division) {
  let policyNumber = division.policy_number.split(".");

  switch(policyNumber[0]) {
    case "10":
      return {hull: division};
    case "20": 
      return { loh: division};
    case "30":
      return { electronicsInsurance: division};
    case "40":
      return { pAndI: division};
    case "50":
      return { hullFranchise: division};
    case "60":
      return { lohFranchise: division};
    case "70":
      return { interest: division};
    default:
      return null;
  }
}

