import { useEffect, useState } from "react";
import * as Yup from "yup";
import { percentagePatternPremium, percentagePatternPremiumShare } from "../../patterns/patterns";
import CorporationDataService from "../../services/corporation.data.service";
import { multipleChoiceCorpForm } from "../Corporation/MultipleChoice/MultipleCorpChoice";

const LohCorporationForm = (props) => {
    const [lohCorpFields, setLohCorpFields] = useState({
        hull: props.editData.loh,
        corporation: "",
        share: "",
        premium_rate: "",
    })

    const data = props.editData;
    useEffect(() => {
        setLohCorpFields({
            loh: data.loh,
            corporation: { value: data.corporation, label: data.corporation.name },
            share: data.share,
            premium_rate: data.premium_rate,
        })
    }, [data]);

    console.log(lohCorpFields)

    const resetLohCorpFields = () => {
        setLohCorpFields({
            loh: props.editData.loh,
            corporation: "",
            share: "",
            premium_rate: "",
        }) 
    }

    const [corporation, setCorporation] = useState([]);

    const getAllCorporations = () => {
        CorporationDataService.getAll({ filter: "" })
          .then((response) => {
            let corporation = [];
            response.data.forEach((element) => {
              corporation.push({ value: element, label: element.name });
            });
            setCorporation(corporation);
          })
          .catch((error) => {
            console.log(error);
          });
    };

    useEffect(() => {
        getAllCorporations();
    }, []);


    const LohCorporationObjectSchema = Yup.object().shape({
        corporation: Yup.object().required("Pflichtfeld").nullable(),
        share: Yup.string()
          .required("Pflichtfeld")
          .matches(
            percentagePatternPremiumShare,
            "Mit Komma trennen und maximal 4 Nachkommstellen"
          ),
        premium_rate: Yup.string()
          .required("Pflichtfeld")
          .matches(
            percentagePatternPremium,
            "Mit Komma trennen und maximal 4 Nachkommstellen"
          ),
    });

    return (
        multipleChoiceCorpForm(LohCorporationObjectSchema, lohCorpFields, resetLohCorpFields, corporation, props.btnText, props.onSubmit)
    );
};

export default LohCorporationForm;