import React, { useEffect, useState }  from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  pricePattern,
  percentagePattern,
  percentagePatternPremium
} from "../../patterns/patterns";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import styles from "./InterestForm.module.scss";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";
import InterestDataService from "../../services/interest.data.service";
import { fillOutFieldsWithDivisonData } from "../../helper/helper";

const InterestChangeForm = (props) => {
  const data = props.editData;
  console.log(data);

  // The states for select fileds (policynumber)
  const [policynumber, setPolicynumber] = useState([]);

  const getAllPolicynumbers = () => {
    InterestDataService.getAllCurrent()
      .then((response) => {
        let policynumbers = [];
        response.data.forEach((element) => {
          console.log(element);
          policynumbers.push({
            value: element,
            label: element,
          });
        });
        console.log(policynumbers);
        setPolicynumber(policynumbers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllPolicynumbers() function the first time this component
  // is rendered.
  useEffect(() => {
    getAllPolicynumbers();
  }, []);

  const InterestObjectSchema = Yup.object().shape({
    policy_number: Yup.object().required("Pflichtfeld").nullable(),
    insurance_sum: Yup.string()
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      )
      .required("Pflichtfeld"),
    insurance_start: Yup.date().required("Pflichtfeld"),
    premium_rate: Yup.string()
      .required("Pflichtfeld")
      .matches(
        percentagePatternPremium,
        "Mit Komma trennen und maximal 4 Nachkommstellen"
      ),
    tax: Yup.string()
      .matches(
        percentagePattern,
        "Mit Komma trennen maximal 2 Nachkommastellen"
      ),
    payment_method: Yup.string().nullable(),
    brokerage: Yup.string()
      .matches(
        percentagePattern,
        "Mit Komma trennen maximal 2 Nachkommastellen"
      )
      .required("Pflichtfeld"),
    hull: Yup.object().nullable(),
    corporation: Yup.object().required("Pflichtfeld").nullable(),
    ship: Yup.object().required("Pflichtfeld").nullable(),
    premium_rate_corp: Yup.string()
      .matches(
        percentagePatternPremium,
        "Mit Komma trennen und maximal 4 Nachkommstellen"
      )
      .required("Pflichtfeld!"),
    insurance_period_from: Yup.date().required("Pflichtfeld"),
    insurance_period_to: Yup.date().required("Pflichtfeld"),
  });

  return (
    <Formik
      validationSchema={InterestObjectSchema}
      onSubmit={(values) => {
        props.onSubmit(values);
      }}
      initialValues={{
        policy_number:
          data !== undefined && data.policy_number !== null
            ? { value: data.policy_number, label: data.policy_number }
            : "",
        insurance_sum: data !== undefined ? data.insurance_sum : "",
        premium: data !== undefined ? data.premium : "",
        premium_rate: data !== undefined ? data.premium_rate : "",
        payment_method: data !== undefined ? data.payment_method : "",
        tax: data !== undefined ? data.tax_one : "",
        insurance_start: data !== undefined ? data.insurance_start : "",
        brokerage: data !== undefined ? data.brokerage : "",
        hull: data !== undefined && data.hull !== null ? data.hull : "",
        termination:
          data !== undefined && data.termination !== null
            ? data.termination
            : "",
        corporation: data !== undefined ? data.corporation : "",
        ship: data !== undefined ? data.ship : "",
        insurance_period_from:
          data !== undefined ? data.insurance_period_from : "",
        insurance_period_to: data !== undefined ? data.insurance_period_to : "",
        premium_rate_corp: data !== undefined ? data.premium_rate_corp : "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        errors,
        values,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridHull">
              <Form.Label>Policennummer</Form.Label>
              <Select
                isClearable
                isSearchable
                id="policy_number"
                name="policy_number"
                placeholder="Wähle Policennummer..."
                onChange={(selected) => {
                  if (selected !== null) {
                    InterestDataService.get(selected.value)
                      .then((response) => {
                        console.log(response.data);
                        const interest = response.data;
                        fillOutFieldsWithDivisonData(
                          interest,
                          values,
                          setFieldValue
                        );
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                  values.policy_number = selected;
                  setFieldValue("policy_number", selected);
                }}
                onBlur={handleBlur}
                onMenuClose={() =>
                  setFieldTouched("policy_number", true, false)
                }
                options={policynumber}
                value={values.policy_number}
                styles={chooseCustomSelectStyle(
                  errors.policy_number,
                  values.policy_number,
                  touched.policy_number
                )}
              />
              {errors.policy_number && touched.policy_number ? (
                <div className={styles["invalid-error"]}>
                  {errors.policy_number}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridInsuranceStart">
                <Form.Label>Versicherungsbeginn</Form.Label>
                <Form.Control
                  name="insurance_start"
                  type="date"
                  placeholder="Versicherungsbeginn"
                  value={values.insurance_start}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_start", true, false)
                  }
                  isValid={touched.insurance_start && !errors.insurance_start}
                  isInvalid={
                    !!errors.insurance_start && touched.insurance_start
                  }
                  disabled={props.isEditing}
                />
                {errors.insurance_start && touched.insurance_start ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_start}
                  </div>
                ) : null}
              </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridInsuranceSum">
              <Form.Label>Versicherungssumme</Form.Label>
              <Form.Control
                name="insurance_sum"
                type="text"
                placeholder="Versicherungssume"
                value={values.insurance_sum}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("insurance_sum", true, false)}
                isValid={touched.insurance_sum && !errors.insurance_sum}
                isInvalid={!!errors.insurance_sum && touched.insurance_sum}
              />
              {errors.insurance_sum && touched.insurance_sum ? (
                <div className={styles["invalid-error"]}>
                  {errors.insurance_sum}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row>
          <Form.Group as={Col} controlId="formGridPremiumRate">
              <Form.Label>Prämiensatz Kunde</Form.Label>
              <Form.Control
                name="premium_rate"
                type="text"
                placeholder="Prämiensatz"
                value={values.premium_rate}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("premium_rate", true, false)}
                isValid={touched.premium_rate && !errors.premium_rate}
                isInvalid={!!errors.premium_rate && touched.premium_rate}
              />
              {errors.premium_rate && touched.premium_rate ? (
                <div className={styles["invalid-error"]}>
                  {errors.premium_rate}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridShare">
              <Form.Label>Prämiensatz Gesell.</Form.Label>
              <Form.Control
                name="premium_rate_corp"
                type="text"
                placeholder="Prämiensatz Gesell."
                value={values.premium_rate_corp}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() =>
                  setFieldTouched("premium_rate_corp", true, false)
                }
                isValid={touched.premium_rate_corp && !errors.premium_rate_corp}
                isInvalid={
                  !!errors.premium_rate_corp && touched.premium_rate_corp
                }
              />
              {errors.premium_rate_corp && touched.premium_rate_corp ? (
                <div className={styles["invalid-error"]}>
                  {errors.premium_rate_corp}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridTaxTwo">
              <Form.Label>Steuer</Form.Label>
              <Form.Control
                name="tax"
                type="text"
                placeholder="Steuer"
                value={values.tax}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("tax", true, false)}
                isValid={touched.tax && !errors.tax}
                isInvalid={!!errors.tax && touched.tax}
              />
              {errors.tax && touched.tax ? (
                <div className={styles["invalid-error"]}>{errors.tax}</div>
              ) : null}
            </Form.Group>
          </Row>
          <div className={styles.submitBtn}>
            <SubmitButton variant="primary" type="submit">
              {props.btnText}
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InterestChangeForm;
