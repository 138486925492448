import React, { useState, useEffect, useCallback } from "react";
import FranchiseDataService from "../../services/franchise.data.service";
import Header from "../Header/Header";
import CrudContent from "../CrudContent/CrudContent";
import FranchiseForm from "./FranchiseForm";
import useAlert from "../../hooks/use-alert";
import GenericAlert from "../Alert/GenericAlert";

const Franchise = () => {
  const [allFranchises, setAllFranchises] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const { show, alertContent, handleAlertVisible } = useAlert();

  // The header of the table.
  const header = ["Id", "Name", "Bearbeiten"];

  // The variables for the modals (add, delete, edit)
  const modalTitle = "P&I Franchise löschen";
  const modalTitleEdit = "P&I Franchise bearbeiten";
  const modalTitleAdd = "P&I Franchise hinzufügen";
  const modalBody = "Möchten Sie die Franchise wirklich löschen?";

  // The data to be edited
  const [editData, setEditData] = useState({});

  // The filter value out of the filter input on top of the table
  const [filter, setFilter] = useState({ filter: "" });

  // Count of header items that need to be skipped
  const skip = 1;

  // Function to get all franchises out of the database;
  const getAllFranchises = useCallback(() => {
    FranchiseDataService.getAll(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          var fran = [];
          response.data.forEach((element) => {
            fran.push({
              id: element.id,
              name: element.name,
            });
          });
          setAllFranchises(fran);
        } else {
          setAllFranchises([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  // Calls the getAllFranchises() function every time the filter value changes
  useEffect(() => {
    getAllFranchises();
  }, [getAllFranchises]);

  // Function to delete a specific franchise item by id
  const deleteItem = (item) => {
    FranchiseDataService.delete(item.id)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Die Franchise mit der Nummer "' +
            item.id +
            '" wurde erfolgreich gelöscht!',
          "success"
        );
        getAllFranchises();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Franchise mit der Nummer "' +
            item.id +
            '" konnte nicht gelöscht werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to edit an specific table record
  const editItem = (item) => {
    FranchiseDataService.update(item.id, item)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Die Franchise mit der Nummer "' +
            item.id +
            '" wurde erfolgreich bearbeitet!',
          "success"
        );
        getAllFranchises();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Franchise mit der Nummer "' +
            item.id +
            '" konnte nicht bearbeitet werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to add a new table record
  const addItem = (item) => {
    FranchiseDataService.create(item)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Die Franchise mit der Nummer "' +
            item.id +
            '" wurde erfolgreich erstellt!',
          "success"
        );
        getAllFranchises();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Franchise mit der Nummer "' +
            item.id +
            '" konnte nicht erstellt werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to set the current filter value.
  const handleFilter = (filterValue) => {
    setFilter(filterValue);
  };

  // Function to set the current edit data.
  const handleEditItem = (item) => {
    setEditData(item);
  };

  return (
    <main>
      <Header />
      <div className="main">
        <CrudContent
          heading="P&I Franchisen"
          items={allFranchises}
          header={header}
          onDelete={deleteItem}
          delete
          modalTitle={modalTitle}
          modalBody={modalBody}
          modalTitleEdit={modalTitleEdit}
          hasForm
          form={
            <FranchiseForm
              editData={editData}
              btnText="Bearbeiten"
              isDisabled
            />
          }
          onEditItemReceive={handleEditItem}
          onEditItem={editItem}
          placeholder={"Filter P&I Franchisen..."}
          modalTitleAdd={modalTitleAdd}
          formAdd={<FranchiseForm btnText="Hinzufügen" />}
          onAddItem={addItem}
          add
          onFilter={handleFilter}
          skip={skip}
          moreInfo={false}
          edit
          loading={isLoading}
          unauthorized={isUnauthorized}
          error={error}
        />
        <GenericAlert
          show={show}
          alertVariant={alertContent.variant}
          alertHeading={alertContent.heading}
          alertBody={alertContent.message}
        />
      </div>
    </main>
  );
};

export default Franchise;
