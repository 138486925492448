import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./Sidenav.scss";

function Sidenav(props) {
  return (
    <div
      style={{
        height: "100vh",
        overflow: "scroll initial",
        position: "fixed",
        zIndex: 100,
      }}
    >
      <ProSidebar collapsed={props.collapsed}>
        <SidebarHeader id="header" className="header">
          <Button
            id="collapseBtn"
            className="collapse-btn"
            onClick={props.onCollapse}
          >
            <i className="bi bi-list"></i>
          </Button>
          <h5 id="menuHeading">SuS</h5>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="circle">
            <SubMenu
              title="Stammdaten"
              icon={
                <i className="bi bi-archive" style={{ color: "white" }}></i>
              }
            >
              <MenuItem>
                Kundenkartei
                <Link to="/policyholder" />
              </MenuItem>
              <MenuItem>
                Schiffe
                <Link to="/insured-object" />
              </MenuItem>
              <MenuItem>
                Gesellschaften
                <Link to="/corporation" />
              </MenuItem>
              <MenuItem>
                Pool
                <Link to="/pool" />
              </MenuItem>
              <MenuItem>
                P&I Franchisen
                <Link to="/franchise" />
              </MenuItem>
            </SubMenu>
            <SubMenu
              title="Sparten"
              icon={<i className="bi bi-shield" style={{ color: "white" }}></i>}
            >
              <MenuItem>
                Kasko (10)
                <Link to="/hull" />
              </MenuItem>
              <MenuItem>
                Kasko-Franchise (50)
                <Link to="/hull-franchise" />
              </MenuItem>
              <MenuItem>
                Interesse (70)
                <Link to="/interest" />
              </MenuItem>
              <MenuItem>
                LOH (20)
                <Link to="/loh" />
              </MenuItem>
              <MenuItem>
                LOH-Franchise (60)
                <Link to="/loh-franchise" />
              </MenuItem>
              <MenuItem>
                Elektronik-Versicherung (30)
                <Link to="/electronics-insurance" />
              </MenuItem>
              <MenuItem>
                P & I (40)
                <Link to="/p-and-i" />
              </MenuItem>
              <MenuItem>
                Gutschrift
                <Link to="/credit" />
              </MenuItem>
            </SubMenu>
            <SubMenu
              title="Übersicht"
              icon={
                <i className="bi bi-clipboard" style={{ color: "white" }}></i>
              }
            >
              <MenuItem>
                Schiff Übersicht
                <Link to="/ship-overview" />
              </MenuItem>
              <MenuItem>
                Schäden
                <Link to="/damage" />
              </MenuItem>
            </SubMenu>
            <SubMenu
              title="Rechnungen"
              icon={
                <i className="bi bi-cash-coin" style={{ color: "white" }}></i>
              }
            >
              <MenuItem>
                Buchhaltungsrechnungen
                <Link to="/accouting-invoice" />
              </MenuItem>
              <MenuItem>
                Kundenrechnungen
                <Link to="/customer-invoice" />
              </MenuItem>
              <MenuItem>
                Gutschriftrechnungen
                <Link to="/credit-invoice" />
              </MenuItem>
            </SubMenu>
            <SubMenu
              title="Abrechnungen"
              icon={
                <i className="bi bi-folder" style={{ color: "white" }}></i>
              }
            >
              <MenuItem>
                Gesellschaftsabrechnungen
                <Link to="/corporation-billing" />
              </MenuItem>
              <MenuItem>
                Kundenabrechnungen
                <Link to="/customer-billing" />
              </MenuItem>
              <MenuItem>
                Versicherungssteuerbrechnungen
                <Link to="/tax-billing" />
              </MenuItem>
            </SubMenu>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
}

export default Sidenav;
