import React, { useState } from "react";
import CustomFormModal from "../UI/Modals/CustomFormModal";
import CustomDeleteModal from "../UI/Modals/CustomDeleteModal";
import Table from "../Table/Table";
import ItemFilter from "../ItemFilter/ItemFilter";
import CustomMoreInfoModal from "../UI/Modals/CustomMoreInfoModal";
import LohMultiModal from "../UI/Modals/Loh/LohMultiModal";

const CrudContentLoh = (props) => {
    const lohCorpSignArray = ["", "%", "%"]
    // Variable to display the delete modal
    const [show, setShow] = useState(false);
    // Variable to display the edit modal
    const [showEdit, setShowEdit] = useState(false);
    // Variable to display the add modal
    const [showAdd, setShowAdd] = useState(false);
    // Variable to display the delete modal
    const [showDisplay, setShowDisplay] = useState(false);

    const [showInvoice, setShowInvoice] = useState(false);

    const [showChange, setShowChange] = useState(false);

    // Variable with the current item which is selected
    const [item, setItem] = useState("");

    // Create a copy of the headers object and delete the last index
    const headers = Object.assign([], props.header).splice(
    0,
    props.header.length - 1
    );
    // Accept the header array
    const header = props.header;

    // Policynumber
    const [loh, setLoh] = useState({});
    const [preventNext, setPreventNext] = useState(true);
    const [division, setDivision] = useState("");
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);

    console.log(loh);

    // Function to handle the delete of an specific entry
  const handleDelete = () => {
    console.log(item);
    props.onDelete(item);
    setShow(false);
  };

  // The functions to open and close the modal
  const handleClose = () => setShow(false);
  const handleShow = (datum) => {
    setItem(datum);
    setShow(true);
  };

  // Function to handle the edit of the items
  const handleEdit = (e) => {
    const data = { ...e, ship: e.ship.value };
    props.onEditItem(data);
  };

  const handleShowEdit = (data) => {
    // Create a copy of the data item and replace all comma with dot
    const item = { ...data };
    replaceComma(item);
    // Change the vz value to a boolean value.
    console.log(item);
    props.onEditItemReceive(item);
    setLoh(item);
    setShowEdit(true);
  };

  // Functions to close and open the modal
  const handleCloseEdit = () => {
    setLoh({});
    setShowEdit(false);
  };

  // Replace all commas with dots.
  // Must be done for all decimal values because
  // the backend only accepts data with a dot.
  const replaceComma = (item) => {
    item.insurance_sum = item.insurance_sum.replace(/,/g, ".");
    item.daily_rate = item.daily_rate.replace(/,/g, ".");
    item.tax = item.tax.replace(/,/g, ".");
    item.brokerage = item.brokerage.replace(/,/g, ".");
    item.premium_rate = item.premium_rate.replace(/,/g, ".");
  };

  // Function to handle adding of new items to the table
  const handleAdd = (e) => {
    console.log(e);
    const data = { ...e };
    console.log(data)
    setLoh(data);
    setPreventNext(false);
    props.onAddItem(data);
  };

  // Function to close and open the modal
  const handleCloseAdd = () => {
    setPreventNext(true);
    setLoh({});
    setShowAdd(false);
  };
  const handleShowAdd = () => {
    setShowAdd(true);
  };

  // Function to handle adding of new items to the table
  const handleChange = (e) => {
    console.log(e);
    const data = { ...e };
    console.log(data);
    props.onChangeItem(data);
    handleCloseChange();
  };

  // Function to close and open the modal
  const handleCloseChange = () => {
    setShowChange(false);
  };
  const handleShowChange = () => {
    setShowChange(true);
  };

  // Function to close and open the modal
  const handleCloseDisplay = () => setShowDisplay(false);
  const handleShowDisplay = (data) => {
    props.onDisplayItemReceive(data);
    setShowDisplay(true);
  };

  // Methode to get the filter value out of the input field
  const handleFilter = (e) => {
    var filter = { filter: e.target.value };
    props.onFilter(filter);
  };

  const handleCloseInvoice = () => {
    setShowInvoice(false);
  };
  const handleShowInvoice = (item) => {
    setHasPaymentMethod(item.payment_method !== '' ? true : false);
    props.dueDatesIsDisabled(item.payment_method !== '' ? true : false)
    setDivision(item);
    setShowInvoice(true);
  };

  const handleInvoiceCreation = (dueDatesList) => {
    handleCloseInvoice();
    const newDivision = { ...division };
    if (dueDatesList !== null) {
      const invoiceCreationData = { div: newDivision, dueDatesList: [] };
      props.onInvoiceCreation(invoiceCreationData);
    } else {
      const invoiceCreationData = {
        div: newDivision,
        dueDatesList: dueDatesList,
      };
      props.onInvoiceCreation(invoiceCreationData);
    }
  };

  const lohObject =
    props.loh && Object.keys(props.loh).length === 0 ? loh : props.loh;

    return (
        <>
          <div>
            <h2>{props.heading}</h2>
            <div className="col">
              <ItemFilter
                placeholder={props.placeholder}
                onChange={handleFilter}
                onAdd={handleShowAdd}
                onAddChange={handleShowChange}
                onYearChange={props.onYearChange}
                hasYearFilter={props.hasYearFilter}
                changeDivision
                add={props.add}
              />
              <Table
                headers={headers}
                header={header}
                items={props.items}
                onEdit={handleShowEdit}
                onDelete={handleShow}
                delete={props.delete}
                onDisplay={handleShowDisplay}
                attr={props.attr}
                tableStyle="tableContainer"
                moreInfo={props.moreInfo}
                signArray={props.signArray}
                edit={props.edit}
                onInvoice={handleShowInvoice}
                invoice={props.invoice}
                loading={props.loading}
                unauthorized={props.unauthorized}
                error={props.error}
              />
            </div>
          </div>
    
          <CustomDeleteModal
            modalTitle={props.modalTitle}
            modalBody={props.modalBody}
            onClose={handleClose}
            onDelete={handleDelete}
            onHide={handleClose}
            show={show}
          />
    
          <LohMultiModal
            show={showAdd}
            onSubmit={handleAdd}
            form={props.formAdd}
            modalTitle={props.modalTitleAdd}
            showCorp={showAdd}
            onSubmitCorp={handleAdd}
            onHide={handleCloseAdd}
            loh={props.loh}
            preventNext={preventNext}
            isEditing={false}
          />
    
          <LohMultiModal
            show={showEdit}
            onSubmit={handleEdit}
            form={props.form}
            modalTitle={props.modalTitleEdit}
            showCorp={showEdit}
            onSubmitCorp={handleEdit}
            onHide={handleCloseEdit}
            loh={lohObject}
            isEditing={true}
          />
    
          <CustomFormModal
            show={showChange}
            onSubmit={handleChange}
            form={props.formChange}
            onHide={handleCloseChange}
            modalTitle="Loh Änderung hinzufügen"
          />
    
          <CustomMoreInfoModal
            show={showDisplay}
            items={props.displayItems}
            onHide={handleCloseDisplay}
            onClose={handleCloseDisplay}
            modalTitle={props.modalTitleAdd}
            hasTable={true}
            attr={{ 0: "name" }}
            header={props.modalHeader}
            headers={props.modalHeaders}
            signArray={lohCorpSignArray}
          />
    
          {props.invoice && (
            <CustomFormModal
              show={showInvoice}
              onSubmit={handleInvoiceCreation}
              leftButton="Schließen"
              rightButton="Direkt generieren"
              onLeftBtn={handleCloseInvoice}
              onRightBtn={handleInvoiceCreation}
              form={
                <div>
                  <p>
                    <b>
                      Es ist immer der Zeitraum von und bis anzugeben. Bei
                      quartalsweiser Zahlung würde für das erste Quartal 01.01 -
                      31.3 angegeben werden.
                    </b>
                  </p>
                  {props.dueDatesForm}
                </div>
              }
              onHide={handleCloseInvoice}
              modalTitle={props.invoiceModalHeader}
              footer
              hasPaymentMethod={hasPaymentMethod}
            />
          )}
        </>
      );
};

export default CrudContentLoh;