import React, { useEffect, useState } from "react";
import styles from "./ElectronicsInsuranceForm.module.scss";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import { Formik } from "formik";
import * as Yup from "yup";
import { pricePattern, percentagePattern } from "../../patterns/patterns";
import CorporationDataService from "../../services/corporation.data.service";
import InsuredObjectDataService from "../../services/insured.object.data.service";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";

const ElectronicsInsuranceForm = (props) => {
  const data = props.editData;

  // The states for select fileds (corporation, ship)
  const [corporation, setCorporation] = useState([]);
  const [ship, setShip] = useState([]);

  // Function to get all corporations out of the database.
  const getAllCorporations = () => {
    CorporationDataService.getAll({ filter: "" })
      .then((response) => {
        let corporation = [];
        response.data.forEach((element) => {
          corporation.push({ value: element, label: element.name });
        });
        setCorporation(corporation);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllCorporations() function the first time this component
  // is rendered.
  useEffect(() => {
    getAllCorporations();
  }, []);

  // Function to get all ships out of the databse.
  const getAllShips = () => {
    InsuredObjectDataService.getAll({ filter: "" })
      .then((response) => {
        console.log(response.data);
        let ships = [];
        response.data.forEach((element) => {
          ships.push({ value: element, label: element.shipname });
        });
        setShip(ships);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllShips() function thr first time this component
  // is rendered.
  useEffect(() => {
    getAllShips();
  }, []);

  const ElectronicsInsuranceSchema = Yup.object().shape({
    insurance_sum: Yup.string()
      .required("Pflichtfeld")
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      ),
    price: Yup.string()
      .required("Pflichtfeld")
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      ),
    insurance_start: Yup.date().required("Pflichtfeld"),
    payment_method: Yup.string().nullable(),
    tax: Yup.string()
      .matches(
        percentagePattern,
        "Mit Komma trennen maximal 2 Nachkommastellen"
      )
      .required("Pflichtfeld"),
    brokerage: Yup.string()
      .matches(
        percentagePattern,
        "Mit Komma trennen maximal 2 Nachkommastellen"
      )
      .required("Pflichtfeld"),
    ship: Yup.object().required("Pflichtfeld").nullable(),
    corporation: Yup.object().required("Pflichtfeld").nullable(),
    insurance_period_from: Yup.date().required("Pflichtfeld"),
    insurance_period_to: Yup.date().required("Pflichtfeld"),
    fixed_premium: Yup.string()
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      )
      .required("Pflichtfeld"),
    fixed_premium_corp: Yup.string()
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      )
      .required("Pflichtfeld"),
  });

  return (
    <>
      <Formik
        validationSchema={ElectronicsInsuranceSchema}
        onSubmit={(values) => {
          const corporation = values.corporation;
          const ship = values.ship;
          console.log(values);
          props.onSubmit(values);
          values.corporation = corporation;
          values.ship = ship;
        }}
        initialValues={{
          policy_number: data !== undefined ? data.policy_number : "",
          insurance_sum: data !== undefined ? data.insurance_sum : "",
          price: data !== undefined ? data.price : "",
          insurance_start: data !== undefined ? data.insurance_start : "",
          payment_method: data !== undefined ? data.payment_method : "",
          tax: data !== undefined ? data.tax : "",
          brokerage: data !== undefined ? data.brokerage : "",
          corporation:
            data !== undefined
              ? { value: data.corporation, label: data.corporation.name }
              : "",
          ship:
            data !== undefined
              ? { value: data.ship, label: data.ship.shipname }
              : "",
          termination:
            data !== undefined && data.termination !== null
              ? data.termination
              : "",
          fifty_one_sum:
            data !== undefined && data.fifty_one_sum !== null
              ? data.fifty_one_sum
              : "",
          insurance_period_from:
            data !== undefined ? data.insurance_period_from : "",
          insurance_period_to:
            data !== undefined ? data.insurance_period_to : "",
          fixed_premium: data !== undefined ? data.fixed_premium : "",
          fixed_premium_corp: data !== undefined ? data.fixed_premium_corp : "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridShip">
                <Form.Label>Schiff</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  id="ship"
                  name="ship"
                  onChange={(selected) => {
                    values.ship = selected;
                    setFieldValue("ship", selected);
                  }}
                  onBlur={handleBlur}
                  onMenuClose={() =>
                    setFieldTouched("corporation", true, false)
                  }
                  placeholder="Wähle ein Schiff..."
                  options={ship}
                  value={values.ship}
                  styles={chooseCustomSelectStyle(
                    errors.ship,
                    values.ship,
                    touched.ship
                  )}
                />
                {errors.ship && touched.ship ? (
                  <div className={styles["invalid-error"]}>{errors.ship}</div>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridCorporation">
                <Form.Label>Gesellschaft</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  id="corporation"
                  name="corporation"
                  placeholder="Wähle Gesellschaft..."
                  onChange={(selected) => {
                    values.corporation = selected;
                    setFieldValue("corporation", selected);
                  }}
                  onBlur={handleBlur}
                  onMenuClose={() =>
                    setFieldTouched("corporation", true, false)
                  }
                  options={corporation}
                  value={values.corporation}
                  styles={chooseCustomSelectStyle(
                    errors.corporation,
                    values.corporation,
                    touched.corporation
                  )}
                />
                {errors.corporation && touched.corporation ? (
                  <div className={styles["invalid-error"]}>
                    {errors.corporation}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridInsuranceSum">
                <Form.Label>Versicherungssumme</Form.Label>
                <Form.Control
                  name="insurance_sum"
                  type="text"
                  placeholder="Versicherungssumme"
                  value={values.insurance_sum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("insurance_sum", true, false)}
                  isValid={touched.insurance_sum && !errors.insurance_sum}
                  isInvalid={!!errors.insurance_sum && touched.insurance_sum}
                />
                {errors.insurance_sum && touched.insurance_sum ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_sum}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="formGridFixedPremium">
                <Form.Label>Festprämie Kunde</Form.Label>
                <Form.Control
                  name="fixed_premium"
                  type="text"
                  placeholder="Festprämie"
                  value={values.fixed_premium}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("fixed_premium", true, false)}
                  isValid={touched.fixed_premium && !errors.fixed_premium}
                  isInvalid={!!errors.fixed_premium && touched.fixed_premium}
                />
                {errors.fixed_premium && touched.fixed_premium ? (
                  <div className={styles["invalid-error"]}>
                    {errors.fixed_premium}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridFixedPremiumCorp">
                <Form.Label>Festprämie Gesell.</Form.Label>
                <Form.Control
                  name="fixed_premium_corp"
                  type="text"
                  placeholder="Festprämie Gesell."
                  value={values.fixed_premium_corp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("fixed_premium_corp", true, false)
                  }
                  isValid={
                    touched.fixed_premium_corp && !errors.fixed_premium_corp
                  }
                  isInvalid={
                    !!errors.fixed_premium_corp && touched.fixed_premium_corp
                  }
                />
                {errors.fixed_premium_corp && touched.fixed_premium_corp ? (
                  <div className={styles["invalid-error"]}>
                    {errors.fixed_premium_corp}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridInsuranceStart">
                <Form.Label>Versicherungsbeginn</Form.Label>
                <Form.Control
                  name="insurance_start"
                  type="date"
                  placeholder="Versicherungsbeginn"
                  value={values.insurance_start}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_start", true, false)
                  }
                  isValid={touched.insurance_start && !errors.insurance_start}
                  isInvalid={
                    !!errors.insurance_start && touched.insurance_start
                  }
                  disabled={props.isEditing}
                />
                {errors.insurance_start && touched.insurance_start ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_start}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPaymentMethod">
                <Form.Label>Zahlungsmethode</Form.Label>
                <Form.Select
                  name="payment_method"
                  value={values.payment_method}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.payment_method && !errors.payment_method}
                  isInvalid={!!errors.payment_method && touched.payment_method}
                >
                  <option hidden>Methode wählen...</option>
                  <option></option>
                  <option>1</option>
                  <option>1/2</option>
                  <option>1/3</option>
                  <option>1/4</option>
                  <option>1/6</option>
                  <option>1/12</option>
                </Form.Select>
                {errors.payment_method && touched.payment_method ? (
                  <div className={styles["invalid-error"]}>
                    {errors.payment_method}
                  </div>
                ) : null}
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridInsurancePeriodFrom">
                <Form.Label>Versicherungszeitraum von</Form.Label>
                <Form.Control
                  name="insurance_period_from"
                  type="date"
                  placeholder="Versicherungszeitraum von"
                  value={values.insurance_period_from}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_period_from", true, false)
                  }
                  isValid={
                    touched.insurance_period_from &&
                    !errors.insurance_period_from
                  }
                  isInvalid={
                    !!errors.insurance_period_from &&
                    touched.insurance_period_from
                  }
                />
                {errors.insurance_period_from &&
                touched.insurance_period_from ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_period_from}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridInsurancePeriodTo">
                <Form.Label>Versicherungszeitraum bis</Form.Label>
                <Form.Control
                  name="insurance_period_to"
                  type="date"
                  placeholder="Versicherungszeitraum bis"
                  value={values.insurance_period_to}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_period_to", true, false)
                  }
                  isValid={
                    touched.insurance_period_to && !errors.insurance_period_to
                  }
                  isInvalid={
                    !!errors.insurance_period_to && touched.insurance_period_to
                  }
                />
                {errors.insurance_period_to && touched.insurance_period_to ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_period_to}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPrice">
                <Form.Label>Preis</Form.Label>
                <Form.Control
                  name="price"
                  type="text"
                  placeholder="Preis"
                  value={values.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("price", true, false)}
                  isValid={touched.price && !errors.price}
                  isInvalid={!!errors.price && touched.price}
                />
                {errors.price && touched.price ? (
                  <div className={styles["invalid-error"]}>{errors.price}</div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridBrokerage">
                <Form.Label>Courtage</Form.Label>
                <Form.Control
                  name="brokerage"
                  type="text"
                  placeholder="Courtage"
                  value={values.brokerage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("brokerage", true, false)}
                  isValid={touched.brokerage && !errors.brokerage}
                  isInvalid={!!errors.brokerage && touched.brokerage}
                />
                {errors.brokerage && touched.brokerage ? (
                  <div className={styles["invalid-error"]}>
                    {errors.brokerage}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridTaxOne">
                <Form.Label>Steuer</Form.Label>
                <Form.Control
                  name="tax"
                  type="text"
                  placeholder="Steuer"
                  value={values.tax}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("tax", true, false)}
                  isValid={touched.tax && !errors.tax}
                  isInvalid={!!errors.tax && touched.tax}
                />
                {errors.tax && touched.tax ? (
                  <div className={styles["invalid-error"]}>{errors.tax}</div>
                ) : null}
              </Form.Group>
            </Row>
            <Row>
              {props.isEditing && (
                <Form.Group as={Col} controlId="formGridInsuranceStart">
                  <Form.Label>Kündigung zum</Form.Label>
                  <Form.Control
                    name="termination"
                    type="date"
                    placeholder="Kündigung zum"
                    value={values.termination}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onInput={() => setFieldTouched("termination", true, false)}
                    isValid={touched.termination && !errors.termination}
                    isInvalid={!!errors.termination && touched.termination}
                  />
                  {errors.termination && touched.termination ? (
                    <div className={styles["invalid-error"]}>
                      {errors.termination}
                    </div>
                  ) : null}
                </Form.Group>
              )}
            </Row>
            <div className={styles.submitBtn}>
              <SubmitButton variant="primary" type="submit">
                {props.btnText}
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ElectronicsInsuranceForm;
