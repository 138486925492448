import React, { useState, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import { Formik } from "formik";
import * as Yup from "yup";
import styles from "./LohFranchiseForm.module.scss";
import { percentagePattern, pricePattern } from "../../patterns/patterns";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";
import LohFranchiseDataService from "../../services/loh-franchise.data.service";
import { fillOutFieldsWithDivisonData } from "../../helper/helper";

const LohFranchiseChangeForm = (props) => {
  // The data to be displayed in the textfields.
  const data = props.editData;

  // The states for select fileds (policynumber)
  const [policynumber, setPolicynumber] = useState([]);

  const getAllPolicynumbers = () => {
    LohFranchiseDataService.getAllCurrent()
      .then((response) => {
        let policynumbers = [];
        response.data.forEach((element) => {
          console.log(element);
          policynumbers.push({
            value: element,
            label: element,
          });
        });
        console.log(policynumbers);
        setPolicynumber(policynumbers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllPolicynumbers() function the first time this component
  // is rendered.
  useEffect(() => {
    getAllPolicynumbers();
  }, []);

  // The object schema to validate the input fields.
  const LohFranchiseSchema = Yup.object().shape({
    policy_number: Yup.object().required("Pflichtfeld").nullable(),
    daily_rate: Yup.string()
      .required("Pflichtfeld")
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      ),
    payment_method: Yup.string().nullable(),
    deductible: Yup.number()
      .required("Pflichtfeld")
      .min(0, "Die SB muss größer oder gleich 0 sein.")
      .max(1000, "Die SB darf den Wert von 1000 nicht überschreiten."),
    hull: Yup.number()
      .required("Pflichtfeld")
      .min(0, "Die Kasko muss größer oder gleich 0 sein.")
      .max(1000, "Die Kasko darf den Wert von 1000 nicht überschreiten."),
    machine: Yup.number()
      .required("Pflichtfeld")
      .min(0, "Die Maschine muss größer oder gleich 0 sein.")
      .max(1000, "Die Maschine darf den Wert von 1000 nicht überschreiten."),
    tax: Yup.string()
      .required("Pflichtfeld")
      .matches(
        percentagePattern,
        "Mit Komma trennen maximal 2 Nachkommastellen"
      ),
    insurance_start: Yup.date().required("Pflichtfeld"),
    brokerage: Yup.string()
      .required("Pflichtfeld")
      .matches(
        percentagePattern,
        "Mit Komma trennen maximal 2 Nachkommastellen"
      ),
    loh: Yup.object().nullable(),
    corporation: Yup.object().required("Pflichtfeld").nullable(),
    ship: Yup.object().required("Pflichtfeld").nullable(),
    insurance_period_from: Yup.date().required("Pflichtfeld"),
    insurance_period_to: Yup.date().required("Pflichtfeld"),
    fixed_premium: Yup.string()
      .required("Pflichtfeld")
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      ),
    fixed_premium_corp: Yup.string()
      .required("Pflichtfeld")
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      ),
  });

  return (
    <>
      <Formik
        validationSchema={LohFranchiseSchema}
        onSubmit={(values) => {
          props.onSubmit(values);
        }}
        initialValues={{
          policy_number:
            data !== undefined && data.policy_number !== null
              ? { value: data.policy_number, label: data.policy_number }
              : "",
          insurance_start: data !== undefined ? data.insurance_start : "",
          daily_rate: data !== undefined ? data.daily_rate : "",
          deductible: data !== undefined ? data.deductible : "",
          hull: data !== undefined ? data.hull : "",
          machine: data !== undefined ? data.machine : "",
          payment_method: data !== undefined ? data.payment_method : "",
          tax: data !== undefined ? data.tax : "",
          termination:
            data !== undefined && data.termination !== null
              ? data.termination
              : "",
          brokerage: data !== undefined ? data.brokerage : "",
          loh: data !== undefined && data.loh !== null ? data.loh : "",
          ship: data !== undefined ? data.ship : "",
          corporation: data !== undefined ? data.corporation : "",
          insurance_period_from:
            data !== undefined ? data.insurance_period_from : "",
          insurance_period_to:
            data !== undefined ? data.insurance_period_to : "",
          fixed_premium: data !== undefined ? data.fixed_premium : "",
          fixed_premium_corp: data !== undefined ? data.fixed_premium_corp : "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridHull">
                <Form.Label>Policennummer</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  id="policy_number"
                  name="policy_number"
                  placeholder="Wähle Policennummer..."
                  onChange={(selected) => {
                    if (selected !== null) {
                      LohFranchiseDataService.get(selected.value)
                        .then((response) => {
                          console.log(response.data);
                          const lohFranchise = response.data;
                          fillOutFieldsWithDivisonData(
                            lohFranchise,
                            values,
                            setFieldValue
                          );
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }
                    values.policy_number = selected;
                    setFieldValue("policy_number", selected);
                  }}
                  onBlur={handleBlur}
                  onMenuClose={() =>
                    setFieldTouched("policy_number", true, false)
                  }
                  options={policynumber}
                  value={values.policy_number}
                  styles={chooseCustomSelectStyle(
                    errors.policy_number,
                    values.policy_number,
                    touched.policy_number
                  )}
                />
                {errors.policy_number && touched.policy_number ? (
                  <div className={styles["invalid-error"]}>
                    {errors.policy_number}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridDailyRate">
                <Form.Label>Tagessatz</Form.Label>
                <Form.Control
                  name="daily_rate"
                  type="text"
                  placeholder="Tagessatz"
                  value={values.daily_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("daily_rate", true, false)}
                  isValid={touched.daily_rate && !errors.daily_rate}
                  isInvalid={!!errors.daily_rate && touched.daily_rate}
                />
                {errors.daily_rate && touched.daily_rate ? (
                  <div className={styles["invalid-error"]}>
                    {errors.daily_rate}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="formGridFixedPremium">
                <Form.Label>Festprämie Kunde</Form.Label>
                <Form.Control
                  name="fixed_premium"
                  type="text"
                  placeholder="Festprämie"
                  value={values.fixed_premium}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("fixed_premium", true, false)}
                  isValid={touched.fixed_premium && !errors.fixed_premium}
                  isInvalid={!!errors.fixed_premium && touched.fixed_premium}
                />
                {errors.fixed_premium && touched.fixed_premium ? (
                  <div className={styles["invalid-error"]}>
                    {errors.fixed_premium}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridDailyRate">
                <Form.Label>Festprämie Gesell.</Form.Label>
                <Form.Control
                  name="fixed_premium_corp"
                  type="text"
                  placeholder="Festprämie Gesell."
                  value={values.fixed_premium_corp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("fixed_premium_corp", true, false)
                  }
                  isValid={
                    touched.fixed_premium_corp && !errors.fixed_premium_corp
                  }
                  isInvalid={
                    !!errors.fixed_premium_corp && touched.fixed_premium_corp
                  }
                />
                {errors.fixed_premium_corp && touched.fixed_premium_corp ? (
                  <div className={styles["invalid-error"]}>
                    {errors.fixed_premium_corp}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridInsuranceStart">
                <Form.Label>Versicherungsbeginn</Form.Label>
                <Form.Control
                  name="insurance_start"
                  type="date"
                  placeholder="Versicherungsbeginn"
                  value={values.insurance_start}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_start", true, false)
                  }
                  isValid={touched.insurance_start && !errors.insurance_start}
                  isInvalid={
                    !!errors.insurance_start && touched.insurance_start
                  }
                  disabled={props.isEditing}
                />
                {errors.insurance_start && touched.insurance_start ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_start}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridInsuranceStart">
                <Form.Label>Versicherungsbeginn</Form.Label>
                <Form.Control
                  name="insurance_start"
                  type="date"
                  placeholder="Versicherungsbeginn"
                  value={values.insurance_start}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_start", true, false)
                  }
                  isValid={touched.insurance_start && !errors.insurance_start}
                  isInvalid={
                    !!errors.insurance_start && touched.insurance_start
                  }
                  disabled={props.isEditing}
                />
                {errors.insurance_start && touched.insurance_start ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_start}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridTax">
                <Form.Label>Steuer</Form.Label>
                <Form.Control
                  name="tax"
                  type="text"
                  placeholder="Steuer"
                  value={values.tax}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("tax", true, false)}
                  isValid={touched.tax && !errors.tax}
                  isInvalid={!!errors.tax && touched.tax}
                />
                {errors.tax && touched.tax ? (
                  <div className={styles["invalid-error"]}>{errors.tax}</div>
                ) : null}
              </Form.Group>
            </Row>
            <div className={styles.submitBtn}>
              <SubmitButton
                className="submitBtn"
                variant="primary"
                type="submit"
              >
                {props.btnText}
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LohFranchiseChangeForm;
